// initial state
const CANADA_TEMPLATE_ID = 1;
const US_TEMPLATE_ID = 3;
const CANADA_LEND_A_TEMPLATE_ID = 10;

const state = () => ({
    scripts: [],
    loading: false,
});

const mutations = {
    setTemplate(state, payload) {
        state.scripts = payload;
    },
    setLoading(state, payload) {
        state.loading = payload;
    }
};

const actions = {
    getTemplateData(context, contextId) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootState.auth.token;

        const templates = {
            [CANADA_TEMPLATE_ID]: {
                id: CANADA_TEMPLATE_ID,
                text: "Basic CA",
                url: process.env.MIX_BASE_URL,
                url_lcembed: process.env.MIX_URL_LCEMBED,
                url_lcembed_modal: process.env.MIX_URL_LCEMBED_MODAL,
            },
            [CANADA_LEND_A_TEMPLATE_ID]: {
                id: CANADA_LEND_A_TEMPLATE_ID,
                text: "Basic LendRch CA",
                url: process.env.MIX_BASE_URL,
                url_lcembed: process.env.MIX_URL_LCEMBED,
                url_lcembed_modal: process.env.MIX_URL_LCEMBED_MODAL,
            },
            [US_TEMPLATE_ID]: {
                id: US_TEMPLATE_ID,
                text: "Basic US",
                url: process.env.MIX_BASE_URL,
                url_lcembed: process.env.MIX_URL_LCEMBED_US,
                url_lcembed_modal: process.env.MIX_URL_LCEMBED_MODAL,
            },
        }

        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(templates[contextId]);
            }, 0);
        });
    },
    loadTemplates(context) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootState.auth.token;

        const usrId = context.rootState.auth.user.ext_user_id;
        const templateData = [];

        if (usrId === 0) {
            templateData.push({
                id: CANADA_TEMPLATE_ID,
                text: "Basic CA",
                url: process.env.MIX_BASE_URL,
                url_lcembed: process.env.MIX_URL_LCEMBED,
                url_lcembed_modal: process.env.MIX_URL_LCEMBED_MODAL,
            });

            templateData.push({
                id: CANADA_LEND_A_TEMPLATE_ID,
                text: "Basic CA (Lending Arch - CA)",
                url: process.env.MIX_BASE_URL,
                url_lcembed: process.env.MIX_URL_LCEMBED,
                url_lcembed_modal: process.env.MIX_URL_LCEMBED_MODAL,
            });
        } else if (usrId === 1329) {
            templateData.push({
                id: CANADA_LEND_A_TEMPLATE_ID,
                text: "Basic CA",
                url: process.env.MIX_BASE_URL,
                url_lcembed: process.env.MIX_URL_LCEMBED,
                url_lcembed_modal: process.env.MIX_URL_LCEMBED_MODAL,
            });
        } else {
            templateData.push({
                id: CANADA_TEMPLATE_ID,
                text: "Basic CA",
                url: process.env.MIX_BASE_URL,
                url_lcembed: process.env.MIX_URL_LCEMBED,
                url_lcembed_modal: process.env.MIX_URL_LCEMBED_MODAL,
            });
        }
        context.commit('setLoading', true);

        return new Promise((resolve, reject) => {
            setTimeout(() => {
                context.commit('setTemplate', templateData);
                context.commit('setLoading', false);
                resolve();
            }, 0);
        });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
