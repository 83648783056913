<template>
    <div>
        <div class="main">
            <div class="header">
                <div class="all-forms-title">
                    <h4>
                        All Templates
                    </h4>
                    <p>
                        Manage your templates below or create a New Template.
                    </p>
                </div>
                <div class="new-form-container">
                    <button class="green-button" @click="showModal()">
                        New Template
                    </button>
                </div>
            </div>
            <div class="display-options">
                <div class="action-grid-icon" @click="selectDisplay('icons')">
                    <GridIcon />
                </div>
                <div class="action-list-icon" @click="selectDisplay('list')">
                    <ListIcon />
                </div>
            </div>

            <div class="all-forms" v-if="display === 'icons'">
                <FormItem
                    v-for="formItem in forms"
                    :key="formItem.id"
                    :form="formItem"
                />
            </div>
            <div class="all-forms-table" v-else>
                <div
                    class="all-forms-table-info"
                    style="display: flex;margin: 1rem;justify-content: space-between;padding: 0 30px;color: #6c757d;"
                >
                    <div
                        style=" display: flex; width: 50%;justify-content: space-between"
                    >
                        <div class="name">Template Name</div>
                        <div class="time">Time</div>
                    </div>
                    <div class="actions">
                        Actions
                    </div>
                </div>
                <FormItemList
                    v-for="formItem in forms"
                    :key="formItem.id"
                    :form="formItem"
                />
            </div>

            <div v-if="noForm" class="no-forms">
                <div class="new-form-arrow">
                    <img :src="arrow_img" />
                </div>
                <div class="create-now">
                    <img :src="builder_img" />
                    <h4>You don't have any forms. Let's create one now!</h4>
                </div>
            </div>
        </div>
        <UploadModal
            v-show="isModalVisible"
            @close="closeModal"
            @clicked="createTemplate"
            :templateId="1"
            :forms="forms"
        />
    </div>
</template>
<script>
import FormItem from "./FormItem";
import FormItemList from "./FormItemList";
import UploadModal from "../utils/UploadLogoModal";
import { mapState, mapActions } from "vuex";
// icons
import ListIcon from "../utils/icons/ListIcon";
import GridIcon from "../utils/icons/GridIcon";

export default {
    components: {
        FormItem,
        FormItemList,
        UploadModal,
        ListIcon,
        GridIcon
    },
    data() {
        return {
            isModalVisible: false,
            noForm: false,
            arrow_img: `https://nyc3.digitaloceanspaces.com/${process.env.MIX_DO_SPACES_BUCKET}/resources/img/add-form-arrow2.png`,
            builder_img: `https://nyc3.digitaloceanspaces.com/${process.env.MIX_DO_SPACES_BUCKET}/resources/img/form_builder.png`,
            display: "icons"
        };
    },
    watch: {
        forms(newValue, oldvalue) {
            if (newValue.length == 0) {
                this.noForm = true;
            } else {
                this.noForm = false;
            }
        }
    },
    computed: mapState({
        forms: state => state.forms.forms
    }),
    created() {
        this.$store.dispatch("forms/getForms");
    },
    methods: {
        showModal() {
            this.isModalVisible = true;
        },
        closeModal() {
            this.isModalVisible = false;
        },
        createTemplate(options) {
            const data = {
                type: options.type,
                name: options.name,
                headerColor: options.headerColor,
                labelColor: options.labelColor,
                primaryColor: options.primaryColor,
                secondaryColor: options.secondaryColor,
                colorScheme: options.colorScheme,
                logo: options.logo,
                logoType: options.logoType,
                templateType: options.templateType,
            };

            this.$store.dispatch("forms/createForm", data).then(res => {
                //Set the tab to display the Form area
                localStorage.setItem("tab", "Form");
                // Redirect to the from created
                window.location.href = "/form/" + res;
            });
        },
        selectDisplay(option) {
            this.display = option;
        }
    }
};
</script>

<style scoped lang="scss">
@import "../../../../resources/sass/_variables.scss";
.main {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.header {
    padding: 1rem;
    margin: 3.5rem;
    margin-bottom: 1.5rem;
    order: 1;
    flex-grow: 1;
    min-width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}
.all-forms-title {
    h4 {
        margin-right: 5px;
        color: $darkBlue;
        font-weight: bold;
    }
    p {
        color: $greyHover;
    }
}
.all-forms {
    order: 3;
    width: 80%;
    margin-bottom: 50px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}
.all-forms-table {
    order: 3;
    width: 80%;
    margin-bottom: 50px;
}
.no-forms {
    order: 2;
    width: 70%;
}
.new-form-arrow {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    img {
        width: 80px;
        height: 100%;
        margin-right: 70px;
        opacity: 0.5;
    }
}
.display-options {
    display: flex;
    order: 2;
    margin-bottom: 1rem;
    flex-direction: row;
    justify-content: flex-end;
    min-width: 80%;
    padding: 0 20px;
    .action-list-icon {
        cursor: pointer;
        svg {
            stroke: $darkBlue;
        }
    }
    .action-list-icon:hover {
        svg {
            stroke: $greyHover;
        }
    }
    .action-grid-icon {
        cursor: pointer;
        margin-right: 15px;
        svg {
            fill: $darkBlue;
        }
    }
    .action-grid-icon:hover {
        svg {
            fill: $greyHover;
        }
    }
}
.create-now {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: $blue;
}

@media only screen and (max-width: 960px) {
    .all-forms {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media only screen and (max-width: 600px) {
    .green-button {
        width: 100%;
    }
    .new-form-container {
        width: 100%;
    }
    .header {
        min-width: 100%;
    }
    .all-forms {
        width: 100%;
        grid-template-columns: repeat(1, 1fr);
    }
}
</style>
