// initial state
const state = () => ({
    token: localStorage.getItem("access_token") || null,
    user: JSON.parse(localStorage.getItem("user")) || {}
});

const getters = {
    isAuthenticated(state) {
        return state.token !== null;
    }
};

const actions = {
    //Login
    retrieveToken(context, credentials) {
        return new Promise((resolve, reject) => {
            axios
                .post("/admin/login", {
                    email: credentials.email,
                    password: credentials.password
                })
                .then(response => {
                    const token = response.data.access_token;
                    localStorage.setItem("access_token", token);
                    context.commit("retrieveToken", token);
                    localStorage.setItem(
                        "user",
                        JSON.stringify(response.data.user)
                    );
                    context.commit("setUser", response.data.user);
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    //Logout
    destroyToken(context) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.state.token;
        if (context.getters.isAuthenticated) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/logout")
                    .then(response => {
                        localStorage.removeItem("access_token");
                        context.commit("destroyToken");
                        localStorage.removeItem("user");
                        resolve(response);
                    })
                    .catch(error => {
                        localStorage.removeItem("access_token");
                        context.commit("destroyToken");
                        localStorage.removeItem("user");
                        reject(error);
                    });
            });
        }
    }
};

const mutations = {
    retrieveToken(state, token) {
        state.token = token;
    },
    setUser(state, user) {
        state.user = user;
    },
    destroyToken(state) {
        state.token = null;
        state.user = {};
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
