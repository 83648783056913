<template>
    <button type="button" class="lc-color-picker-cancel" @click="action">
        Cancel
    </button>
</template>

<script>
export default {
    props: {
        action: { type: Function }
    }
};
</script>

<style></style>
