import Login from "./components/auth/Login";
import Logout from "./components/auth/Logout";
import Products from "./components/products/Products";
import Forms from "./components/forms/Forms";
import FormEdit from "./components/forms/Edit";
// import FormCreate from "./components/forms/FormCreate";
import Guide from "./components/Guide";
import store from "./store";

function ifNotAuthenticated(to, from, next) {
    if (!store.getters["auth/isAuthenticated"]) {
        next();
        return;
    }
    next("/forms");
}

function ifAuthenticated(to, from, next) {
    if (store.getters["auth/isAuthenticated"]) {
        next();
        return;
    }
    //redirect to loanconnect
    window.location.href = "https://loanconnect.ca/account/dashboard";
    // next("/admin/login");
}

function ifPartner(to, from, next) {
    if (store.state.auth.user.user_type == 2) {
        next();
        return;
    }
    //redirect to loanconnect
    window.location.href = "https://loanconnect.ca/account/dashboard";
    // next("/admin/login");
}
function ifLender(to, from, next) {
    if (store.state.auth.user.user_type == 1) {
        next();
        return;
    }
    //redirect to loanconnect
    window.location.href = "https://loanconnect.ca/account/dashboard";
    // next("/admin/login");
}

export const routes = [
    {
        path: "/",
        alias: "/forms",
        name: "forms",
        component: Forms,
        beforeEnter: ifAuthenticated && ifPartner
    },
    {
        path: "/admin/login",
        name: "login",
        component: Login,
        beforeEnter: ifNotAuthenticated
    },
    {
        path: "/logout",
        name: "logout",
        component: Logout,
        beforeEnter: ifAuthenticated
    },
    {
        path: "/products",
        name: "products",
        component: Products,
        beforeEnter: ifAuthenticated && ifLender
    },
    {
        path: "/form/:id",
        name: "edit",
        component: FormEdit,
        beforeEnter: ifAuthenticated && ifPartner
    },
    {
        path: "/install-guide",
        name: "guide",
        component: Guide
    },
    {
        path: "/*",
        component: Forms,
        beforeEnter: ifAuthenticated && ifPartner
    }
];
