<template>
    <div>
        <nav
            class="navbar navbar-expand-lg navbar-light shadow-sm bg-white"
            style="display: flex; justify-content: space-between"
        >
            <a class="navbar-brand " href="/">
                <img
                    class="logo"
                    :src="logo"
                    alt="LoanConnect"
                    loading="lazy"
                />
            </a>

            <div v-if="isAuthenticated" class="form-inline my-2 my-lg-0">
                <router-link to="/products" v-if="user.user_type == 1">
                    <a class="btn btn-outline-link my-2 my-sm-0">Products</a>
                </router-link>
                <!-- only display the link if user is partner -->
                <!-- <router-link to="/forms" v-if="user.user_type == 2"> -->
                <a
                    v-if="user.user_type == 2"
                    href="/forms"
                    class="btn btn-outline-link my-2 my-sm-0"
                    >Templates</a
                >
                <!-- </router-link> -->

                <a
                    :href="dashboardUrl"
                    class="btn btn-outline-link my-2 my-sm-0"
                    >Dashboard</a
                >

                <!-- <router-link to="/logout">
                    <a class="btn btn-outline-link my-2 my-sm-0">Logout</a>
                </router-link> -->
            </div>
        </nav>
    </div>
</template>

<script>
import logo from "../../img/lc-logo.png";

const defaultLoanConnectDashboard = 'https://services.loanconnect.ca/partner/kpis';

export default {
    name: "navbar",
    data() {
        return {
            logo,
            collapse: true
        };
    },
    methods: {
        toggleNavigation() {
            this.collapse = false;
        }
    },
    computed: {
        dashboardUrl() {
            return process.env.MIX_DASHBOARD_URL || defaultLoanConnectDashboard;
        },
        isAuthenticated() {
            return this.$store.getters["auth/isAuthenticated"];
        },
        user() {
            return this.$store.state.auth.user;
        }
    }
};
</script>

<style lang="scss">
.logo {
    width: 200px;
}
.navbar {
    // min-height: 90px;
    // min-width: 560px;
    .btn {
        font-size: 1.2rem;
        font-weight: 700;
        color: #356b81;
        &:hover {
            color: #6bc9de;
        }
    }
}
.navbar-brand {
    margin-left: 1rem;
}
</style>
