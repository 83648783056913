<template>
  <tr>
    <td>{{ product.id }}</td>
    <td v-if="!editing" class="name">{{ product.name }}</td>
    <td v-else>
      <input type="text" v-model="product.name" class="form-control" />
    </td>
    <td class="text-center">
      <button v-if="!editing" @click="editProduct" class="btn btn-secondary">Edit</button>
      <button v-else @click="updateProduct" class="btn btn-info">Update</button>
    </td>
    <td class="text-center">
      <button @click="deleteProduct(product.id)" class="btn btn-danger">Delete</button>
    </td>
  </tr>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "product",
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      id: this.product.id,
      name: this.product.name,
      editing: this.product.editing,
    };
  },
  methods: {
    editProduct() {
      this.editing = true;
    },
    updateProduct() {
      this.editing = false;
      this.$store.dispatch("products/updateProduct", {
        id: this.product.id,
        name: this.product.name,
      });
    },
    deleteProduct(id) {
      this.$store.dispatch("products/deleteProduct", id);
    },
  },
};
</script>

<style scoped>
button {
    width: 100px;
}
input {
    width: 300px;
}
.name {
    width: 320px;
}
</style>
