<template>
    <v-app>
        <div>
            <navbar />
            <router-view />
        </div>
    </v-app>
</template>
<script>
import navbar from "./components/Navbar";
import {mapState} from "vuex";

export default {
  components: {
    navbar,
  },
  computed: {
    ...mapState({
        user: state => state.auth.user
    }),
    isAuthenticated() {
      return this.$store.getters["auth/isAuthenticated"];
    },
  },
  beforeMount() {
      if (this.isAuthenticated) {
          this.$store.dispatch('templates/loadTemplates');
      }
  }
};
</script>
