var template = `
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
		<path d="M452.677,273.637c-4.428,0-8.017,3.589-8.017,8.017v214.313H213.245c-4.428,0-8.017,3.589-8.017,8.017
			s3.588,8.017,8.017,8.017h239.432c4.428,0,8.017-3.589,8.017-8.017V281.653C460.694,277.226,457.104,273.637,452.677,273.637z"/>
		<path d="M508.829,189.756L260.846,1.631c-2.865-2.173-6.827-2.173-9.691,0l-98.303,74.574V50.24h9.086
			c4.428,0,8.017-3.589,8.017-8.017V8.018c0-4.427-3.588-8.017-8.017-8.017H42.223c-4.428,0-8.017,3.589-8.017,8.017v34.205
			c0,4.427,3.588,8.017,8.017,8.017h9.086v103L3.172,189.756c-2.742,2.08-3.851,5.68-2.752,8.942
			c1.097,3.263,4.156,5.461,7.598,5.461h43.29v299.824c0,4.427,3.588,8.017,8.017,8.017h102.614c4.428,0,8.017-3.589,8.017-8.017
			s-3.588-8.017-8.017-8.017H67.341V196.142c0-4.427-3.588-8.017-8.017-8.017H31.849L256.001,18.081l224.151,170.046h-27.475
			c-4.428,0-8.017,3.589-8.017,8.017v42.756c0,4.427,3.588,8.017,8.017,8.017c4.428,0,8.017-3.589,8.017-8.017V204.16h43.29
			c3.443,0,6.501-2.198,7.598-5.461C512.679,195.436,511.571,191.837,508.829,189.756z M50.239,34.206V16.035h103.682v18.171H50.239
			z M136.82,88.369l-69.478,52.707V50.24h69.478V88.369z"/>
		<path d="M426.929,406.677l-72.697-85.508c-1.377-1.62-3.341-2.626-5.46-2.798l-24.108-1.952c-0.057-0.004-0.113-0.002-0.171-0.005
			l-15.826-18.615l35.9-35.901l6.424,6.424c3.088,3.087,8.251,3.087,11.338,0l24.186-24.186c0.464-0.464,0.868-0.982,1.206-1.544
			l18.14-30.234c1.893-3.154,1.395-7.193-1.206-9.793l-12.092-12.092c-2.601-2.601-6.639-3.1-9.793-1.206l-30.233,18.14
			c-0.562,0.337-1.082,0.742-1.545,1.206L326.805,232.8c-3.086,3.086-3.086,8.251,0,11.337l6.424,6.424l-34.982,34.983
			l-37.901-44.579l14.573-12.389l67.666-23.985c5.202-1.844,7.009-8.55,3.422-12.758l-25.239-29.595
			c-12.484-14.683-29.936-23.626-49.147-25.182c-19.215-1.556-37.874,4.464-52.556,16.946l-75.329,64.044l-1.195-1.406
			c-4.16-4.894-9.978-7.876-16.381-8.394c-6.391-0.516-12.625,1.487-17.52,5.649l-14.658,12.462
			c-9.986,8.49-11.24,23.909-2.745,33.901l62.312,73.292c8.486,9.986,23.91,11.239,33.901,2.745l14.658-12.462
			c5.169-4.394,8.258-10.845,8.462-17.625c0.199-6.607-2.39-12.363-6.599-17.314l18.101-15.389l40.534,47.679l-15.3,15.3
			l-6.423-6.423c-1.504-1.504-3.542-2.348-5.669-2.348h-24.186c-2.126,0-4.164,0.844-5.669,2.348L126,409.42
			c-9.271,9.271-9.272,24.741-0.001,34.012l25.697,25.698c9.271,9.243,24.746,9.266,34.013,0l79.361-79.362
			c1.503-1.503,2.347-3.542,2.347-5.668v-24.186c0-2.126-0.844-4.165-2.347-5.668l-6.424-6.424l14.382-14.382l16.388,19.276
			l-1.401,17.309c-0.171,2.12,0.507,4.22,1.883,5.839l72.697,85.508c8.477,9.971,23.924,11.228,33.9,2.745l27.689-23.539
			C434.17,432.089,435.425,416.67,426.929,406.677z M361.633,220.649l24.02-14.412l3.24,3.241l-14.411,24.02l-17.82,17.82
			l-12.849-12.849L361.633,220.649z M174.373,457.793c-3.129,3.126-8.213,3.124-11.339,0h0.001l-25.698-25.698
			c-3.089-3.09-3.088-8.248,0.001-11.337l67.645-67.646l37.035,37.035L174.373,457.793z M251.387,376.841l-33.095-33.095h13.605
			l9.742,9.741c0.001,0.001,0.002,0.002,0.002,0.003c0,0.001,0.002,0.001,0.003,0.002l9.743,9.743V376.841z M270.332,153.037
			c14.941,1.21,28.516,8.165,38.232,19.595l17.687,20.74l-52.868,18.739l-40.998-48.223
			C243.306,155.766,256.643,151.927,270.332,153.037z M194.524,306.155c-0.173,2.135-1.167,4.074-2.797,5.46c0,0,0,0-0.001,0
			l-14.659,12.462c-3.33,2.831-8.472,2.411-11.299-0.915l-62.312-73.292c-2.832-3.331-2.413-8.471,0.915-11.3l14.659-12.462
			c3.331-2.833,8.47-2.412,11.299,0.915l62.312,73.292C194.028,301.946,194.696,304.021,194.524,306.155z M193.589,276.678
			l-39.465-46.419l65.962-56.08l39.465,46.419L193.589,276.678z M234.291,263.119l13.843-11.77l62.699,73.747l-13.844,11.771
			L234.291,263.119z M413.801,428.361c0,0,0,0-0.001,0l-27.689,23.54c-3.33,2.831-8.47,2.414-11.299-0.915l-70.546-82.978
			l1.415-17.488l20.999-17.854l17.487,1.416l70.547,82.979C417.547,420.391,417.128,425.532,413.801,428.361z"/>
</svg>
`;

export default {
    template: template
};
