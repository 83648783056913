// initial state
const state = () => ({
    products: []
});

const getters = {};

const actions = {
    getAllProducts(context) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootState.auth.token;
        return new Promise((resolve, reject) => {
            axios
                .get("/products")
                .then(response => {
                    context.commit("getAllProducts", response.data);
                })
                .catch(error => {
                    console.log(error);
                });
        });
    },
    addProduct(context, product) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootState.auth.token;
        axios
            .post("/products", { name: product.name })
            .then(response => {
                context.commit("addProduct", response.data);
            })
            .catch(error => {
                console.log(error);
            });
    },

    updateProduct(context, product) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootState.auth.token;
        axios
            .patch("/products/" + product.id, {
                id: product.id,
                name: product.name
            })
            .then(response => {
                context.commit("updateProduct", response.data);
            })
            .catch(error => {
                console.log(error);
            });
    },

    deleteProduct(context, id) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootState.auth.token;
        axios
            .delete("/products/" + id)
            .then(response => {
                context.commit("deleteProduct", id);
            })
            .catch(error => {
                console.log(error);
            });
    }
};

const mutations = {
    getAllProducts(state, products) {
        state.products = products;
    },
    addProduct(state, product) {
        state.products.push(product);
    },
    updateProduct(state, index, product) {
        state.products[index] = product;
    },
    deleteProduct(state, id) {
        const index = state.products.findIndex(item => item.id == id);
        state.products.splice(index, 1);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
