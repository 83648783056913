<template>
    <div>
        <div class="edit-loans" v-show="displayLoanType">
            <div class="loan-type">
                <p class="title">Loan Type options</p>
                <Tooltip
                    msg="Select the Loan Types you want to appear as options on the application form. As you select, or deselect options you will see the available options to the customer change. If you only select one Loan Type, the Loan Purpose section will be hidden from the customer’s view and they will be applying for that specific Loan Purpose. IMPORTANT: Reducing the Loan Types may reduce the number of loan options available to applicants - eg. a Lender Loan Product may only be available for 'Bills & Expenses'"
                />
            </div>
            <div class="loan-options">
                <div
                    v-for="(item, index) in styles.loanTypeSelected"
                    :key="index"
                    class="loan-item"
                >
                    <label class="container-checkbox" :for="item.icon">
                        {{ item.type }}
                        <input
                            type="checkbox"
                            :id="item.icon"
                            :value="item.type"
                            v-model="item.selected"
                            @change="updateOption"
                        />
                        <span
                            class="option-checkmark"
                            :id="item.icon"
                            :value="item.type"
                            :model="item.selected"
                            @change="updateOption"
                        ></span>
                    </label>
                </div>
            </div>
            <p
                v-if="!this.styles.showLoanType && !oneOption"
                class="title"
                style="color: red;font-size: 1rem;"
            >
                *The Loan Type selected will be pre-selected and hidden from
                users
            </p>
            <p
                v-if="oneOption"
                class="title"
                style="color: red;font-size: 1rem;"
            >
                *You need to select at least one Loan Type
            </p>
        </div>
        <div class="edit-loans">
            <div class="loan-type">
                <p class="title">Single Lender Link</p>
                <Tooltip
                    msg="Selecting a single lender from the dropdown will add their logo to the form, and upon form submission by the customer, only results from that lender will be shown."
                />
            </div>
            <div class="loan-options">
                <v-select
                    v-model="lenderSelected"
                    :value="$store.myValue"
                    :options="lenderOptions"
                    @input="setSingleLender"
                    placeholder="Select a Lender"
                />
            </div>
        </div>
        <div class="edit-loans">
            <div class="loan-type">
                <p class="title">Campaign Tracking</p>
                <Tooltip
                    msg="Enter any additional parameters below to be tagged to your leads. All parameter data will be viewable in your partner portal."
                />
            </div>
            <div class="edit-input edit-row border-0">
                <div class="input">
                    <label for="btnTxt">UTM Source</label>
                    <input type="text" v-model="styles.campaign.utm_source" />
                </div>
            </div>
            <div class="edit-input edit-row border-0">
                <div class="input">
                    <label for="btnTxt">UTM Campaign</label>
                    <input type="text" v-model="styles.campaign.utm_campaign" />
                </div>
            </div>
            <div class="edit-input edit-row border-0">
                <div class="input">
                    <label for="btnTxt">UTM Medium</label>
                    <input type="text" v-model="styles.campaign.utm_medium" />
                </div>
            </div>
            <transition name="slide-fade">
                <div v-if="moreOptions">
                    <div class="edit-input edit-row border-0">
                        <div class="input">
                            <label for="btnTxt">UTM Content</label>
                            <input
                                type="text"
                                v-model="styles.campaign.utm_content"
                            />
                        </div>
                    </div>
                    <div class="edit-input edit-row border-0">
                        <div class="input">
                            <label for="btnTxt">UTM Term</label>
                            <input
                                type="text"
                                v-model="styles.campaign.utm_term"
                            />
                        </div>
                    </div>
                    <div class="edit-input edit-row border-0">
                        <div class="input">
                            <label for="btnTxt">EXT PID</label>
                            <input
                                type="text"
                                v-model="styles.campaign.ext_pid"
                            />
                        </div>
                    </div>
                    <div class="edit-input edit-row border-0">
                        <div class="input">
                            <label for="btnTxt">EXT GID</label>
                            <input
                                type="text"
                                v-model="styles.campaign.ext_gid"
                            />
                        </div>
                    </div>
                    <div class="edit-input edit-row border-0">
                        <div class="input">
                            <label for="btnTxt">EXT A</label>
                            <input
                                type="text"
                                v-model="styles.campaign.ext_a"
                            />
                        </div>
                    </div>
                    <div class="edit-input edit-row border-0">
                        <div class="input">
                            <label for="btnTxt">EXT B</label>
                            <input
                                type="text"
                                v-model="styles.campaign.ext_b"
                            />
                        </div>
                    </div>
                    <div class="edit-input edit-row border-0">
                        <div class="input">
                            <label for="btnTxt">EXT C</label>
                            <input
                                type="text"
                                v-model="styles.campaign.ext_c"
                            />
                        </div>
                    </div>
                </div>
            </transition>
            <div @click="showMoreTracking" class="show-more-less">
                {{ showText }}
            </div>
        </div>
    </div>
</template>

<script>
import AlertSuccess from "../../utils/AlertSuccess";
import Tooltip from "../../utils/Tooltip";
import vSelect from "vue-select";
import { mapState } from "vuex";

export default {
    components: {
        AlertSuccess,
        Tooltip,
        "v-select": vSelect
    },
    props: {
        styles: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            oneOption: false,
            lenderOptions: [],
            lenderSelected: this.styles.singleLender.name,
            moreOptions: false,
            showText: "Show more",
            displayLoanType: true
        };
    },
    created() {
        this.$store.dispatch("users/getLenders");
        this.displayLoanType = !this.styles.singleLender.is_active;
    },
    computed: {
        ...mapState({
            lenders: state => state.users.lenders
        })
    },
    watch: {
        // watch changes on lenders
        lenders(newValue, oldValue) {
            this.lenderOptions = newValue;
        }
    },
    methods: {
        showMoreTracking() {
            this.moreOptions = !this.moreOptions;
            if (this.showText == "Show more") {
                this.showText = "Show less";
            } else {
                this.showText = "Show more";
            }
        },
        setSingleLender(value) {
            if (value) {
                this.styles.singleLender.is_active = true;
                this.styles.singleLender.id = value.code;
                this.styles.singleLender.name = value.label;
                this.styles.singleLender.logo = value.logo;
                // Hide Loan Type Options
                this.displayLoanType = false;
                this.styles.showLoanType = false;
            } else {
                this.styles.singleLender.is_active = false;
                this.styles.singleLender.id = "";
                this.styles.singleLender.name = "";
                this.styles.singleLender.logo = "";
                // Show Loan Type Options
                this.displayLoanType = true;
                this.styles.showLoanType = true;
            }
        },
        updateOption() {
            let isSelected = this.styles.loanTypeSelected.filter(
                item => item.selected
            );
            if (isSelected.length == 1) {
                this.styles.showLoanType = false;
                this.oneOption = false;
                document
                    .getElementById("save-edit")
                    .removeAttribute("disabled", "disabled");
            }
            if (isSelected.length > 1) {
                this.styles.showLoanType = true;
            }
            if (isSelected.length == 0) {
                this.oneOption = true;
                document
                    .getElementById("save-edit")
                    .setAttribute("disabled", "disabled");
            }
        }
    }
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
@import "../../../../../resources/sass/_variables.scss";

.v-select {
    width: 100%;
}
.vs__dropdown-toggle {
    height: 60px;
    cursor: pointer !important;
    border: 1px solid $inputBorder !important;
    border-radius: 5px !important;
}
.vs__dropdown-menu {
    border-radius: 0 !important;
}
.vs__search {
    cursor: pointer !important;
}
.edit-loans {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    .loan-options {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        // margin: 20px 0;
        .loan-item {
            width: 50%;
            input,
            label {
                cursor: pointer;
                font-size: 1rem;
            }
        }
    }
}
.loan-type {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    p {
        margin-bottom: 0px;
        margin-right: 5px;
    }
}
.vs__dropdown-menu {
    z-index: 1;
}
.border-0 {
    border: 0 !important;
}
.show-more-less {
    margin: 0 5px 20px 0;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    justify-content: flex-end;
}
.slide-fade-enter-active {
    transition: all 0.3s ease;
}
.slide-fade-leave-active {
    transition: all 0.3s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
    transform: translateX(10px);
    opacity: 0;
}

/////////
/* The container */
.container-checkbox {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.container-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom checkbox */
.container-checkbox .option-checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 20px;
    width: 20px;
    border: 1px solid $darkBlue;
    border-radius: 3px;
}

/* On mouse-over, add a grey background color */
.container-checkbox:hover input ~ .option-checkmark {
    background-color: $inputBorder;
}

/* When the checkbox is checked, add a blue background */
.container-checkbox input:checked ~ .option-checkmark {
    background-color: $darkBlue;
}

/* Create the option-checkmark/indicator (hidden when not checked) */
.container-checkbox .option-checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the option-checkmark when checked */
.container-checkbox input:checked ~ .option-checkmark:after {
    display: block;
}

/* Style the option-checkmark/indicator */
.container-checkbox .option-checkmark:after {
    left: 6px;
    top: 1px;
    width: 7px;
    height: 12px;
    border: solid #fff;
    border-width: 0 3px 3px 0;
    border-radius: 1px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
</style>
