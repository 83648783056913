<template>
    <div class="form-group">
        <div class="text-center mt-3">
            <h1>Products</h1>
        </div>
        <div class="p-5">
            <form @submit.prevent="addProduct">
                <input
                    type="text"
                    v-model="product.name"
                    placeholder="Product Name"
                    class="form-control"
                />
                <button class="btn btn-success btn-block mt-3">
                    Add new product
                </button>
            </form>
        </div>
        <div class="p-5">
            <table class="table table-bordered">
                <tbody>
                    <product
                        v-for="product in products"
                        :key="product.id"
                        :product="product"
                    ></product>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import Product from "./Product";
import { mapState, mapActions } from "vuex";
export default {
    components: {
        Product
    },
    data() {
        return {
            product: {
                name: ""
            }
        };
    },
    computed: mapState({
        products: state => state.products.products
    }),

    created() {
        this.$store.dispatch("products/getAllProducts");
    },
    methods: {
        addProduct() {
            this.$store.dispatch("products/addProduct", this.product);
            this.product.name = "";
        }
    }
};
</script>

<style scoped></style>
