var template = `
<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 432 432" style="enable-background:new 0 0 432 432;" xml:space="preserve">
		<path d="M413.2,95.4c-11.6-11.6-27.6-18.8-45.2-18.8h-69.2v-22c0-9.2-3.6-17.6-9.6-23.6l-0.4-0.4c-6.4-6.4-14.8-10-24-10h-97.2
			c-9.6,0-18,4-24,10c-6.4,6.4-10,14.8-10,24v22H64c-17.6,0-33.6,7.2-45.2,18.8C7.2,107,0,123,0,140.6v206.8
			C0,365,7.2,381,18.8,392.6c11.6,11.6,27.6,18.8,45.2,18.8h304c17.6,0,33.6-7.2,45.2-18.8C424.8,381,432,365,432,347.4V140.6
			C432,123,424.8,107,413.2,95.4z M148.8,54.6h0.4c0-5.2,2-9.6,5.2-12.8c3.2-3.2,8-5.2,12.8-5.2H264c5.2,0,9.6,2,12.8,5.2l0.4,0.4
			c3.2,3.2,5.2,7.6,5.2,12.4v22h-16v-7.2c0-5.6-2.4-10.8-6.8-14.8l-0.4-0.4c-4-3.6-9.6-6-16-6H188c-6,0-11.6,2.4-16,6
			c-4.4,4-7.2,9.2-7.2,15.2v7.2h-16V54.6z M251.6,69.8v6.8h-0.4h-70.4v-7.2c0-1.2,0.8-2.4,1.6-3.2c1.2-1.2,3.2-2,5.6-2h56
			c2,0,4,0.8,5.6,2l0.4,0.4C250.8,67.4,251.6,68.6,251.6,69.8z M402.4,381.4c-8.8,8.8-20.8,14-34,14H64c-13.2,0-25.2-5.6-34-14
			c-8.8-8.8-14-20.8-14-34V140.6c0-13.2,5.6-25.2,14-34c8.8-8.8,20.8-14,34-14h77.6h31.6h86h31.6H368c13.2,0,25.2,5.6,34,14
			c8.8,8.8,14,20.8,14,34v206.8h0.4C416.4,360.6,410.8,372.6,402.4,381.4z"/>

		<path d="M326.8,273l-16.4-16.4c-2-2-5.2-2.8-8-2l-30,9.2l-35.6-37.6l78-43.2c0.8-0.4,1.6-0.8,2.4-1.6c3.2-3.2,3.2-8,0-11.2
			L296,147.8c-2-2-5.2-2.8-8-2l-98,30l-33.2-35.2l-0.4-0.8c-6-6-14-8.8-22-8.8s-15.6,2.8-22,8.8l-0.8,0.8l-0.8,0.8
			c-5.6,6-8.8,14-8.8,22s2.8,16,8.8,22c0.4,0.4,0.4,0.4,0.8,0.8l35.2,33.2l-29.6,98c-0.8,2.8-0.4,6,2,8l21.6,21.6
			c0.4,0.4,1.2,0.8,1.6,1.2c4,2,8.8,0.8,10.8-3.2l43.6-78.8l37.6,35.6l-8.8,29.6c-0.8,2.8-0.4,6,2,8l16.8,16.8
			c0.4,0.4,1.2,0.8,1.6,1.2c4,2,8.8,0.8,10.8-3.2l21.2-38v-0.4l2.8-6l5.6-2.8c0.4,0,0.4-0.4,0.8-0.4l37.2-20.8
			c0.8-0.4,1.6-0.8,2.4-1.6C330,281,330,276.2,326.8,273z M280,293l-8,4c-1.6,0.8-2.8,2-3.6,3.6l-4,8l-16,28.8l-6-6l9.2-30
			c0.8-3.2,0-6.4-2.4-8.4l-48.4-45.6c-3.2-3.2-8.4-2.8-11.2,0.4c-0.4,0.4-0.8,1.2-1.2,2l-43.6,77.6L134,316.6l30-98
			c0.8-3.2,0-6.4-2.4-8.4l-38.4-36.4l-0.4-0.4c-2.8-2.8-4.4-6.8-4.4-10.4c0-4,1.6-8,4.4-11.2c0,0,0,0,0.4-0.4c0.4-0.4,0,0,0.4-0.4
			c3.2-2.8,7.2-4.4,11.2-4.4s7.6,1.2,10.4,4l0.4,0.4l36.8,38.8c2,2.4,5.2,3.2,8,2l98.4-29.6l10.8,10.8l-78.8,44
			c-4,2-5.2,6.8-3.2,10.8c0.4,0.4,0.8,1.2,1.2,1.6l46,48.8c2,2.4,5.2,3.2,8,2l30-9.2l6,6L280,293z"/>
</svg>

`;

export default {
    template: template,
};
