<template>
    <div>
        <div class="embed-button">
            <p>Embed Button</p>
            <Tooltip
                msg="This ‘Button’ is an alternate option to embed on a webpage where the full form doesn’t fit. The form will appear when the button is clicked. You can customize the button below."
            />
        </div>
        <!-- Button Text -->
        <div class="edit-row edit-input mt-3">
            <div class="input">
                <label for="btnTxt">Button Text</label>
                <input
                    id="btnTxt"
                    type="text"
                    v-model="btnTxt"
                    @input="updateBtnTxt(btnTxt)"
                />
            </div>
        </div>
        <!-- Button Color -->
        <div class="edit-row">
            <p class="title">Button color</p>
            <div
                class="toggle"
                @click="openBtnColorPicker()"
                v-click-outside="closeBtnColorPicker"
            >
                <div
                    class="color-picker btn-color"
                    :style="btnColorStyle"
                ></div>
                <chrome-picker
                    v-show="isBtnColorActive"
                    v-model="btnColor"
                    @input="updateBtnColor"
                ></chrome-picker>
            </div>
        </div>
        <!-- Button Border Color -->
        <div class="edit-row">
            <p class="title">Button Border color</p>
            <div
                class="toggle"
                @click="openBtnBorderColorPicker()"
                v-click-outside="closeBtnBorderPicker"
            >
                <div
                    class="color-picker btn-border-color"
                    :style="btnBorderStyle"
                ></div>
                <chrome-picker
                    v-show="isBtnBorderActive"
                    v-model="btnBorderColor"
                    @input="updateBtnBorder"
                ></chrome-picker>
            </div>
        </div>
        <!-- Button Text Color -->
        <div class="edit-row">
            <p class="title">Button Text color</p>
            <div
                class="toggle"
                @click="openBtnTxtColorPicker()"
                v-click-outside="closeBtnTxtColorPicker"
            >
                <div
                    class="color-picker btn-txt-color"
                    :style="btnTxtColorStyle"
                ></div>
                <chrome-picker
                    v-show="isBtnTxtColorActive"
                    v-model="btnTxtColor"
                    @input="updateBtnTxtColor"
                ></chrome-picker>
            </div>
        </div>
        <!-- Button radius  -->
        <div class="edit-row range">
            <div class="title">
                <label for="btnRadius">Button radius</label>
                <input
                    id="btnRadius"
                    type="range"
                    min="0"
                    max="40"
                    step="5"
                    v-model="btnRadius"
                    @input="updateBtnRadius(btnRadius)"
                />
            </div>
            <div class="toggle">
                {{ btnRadius }}
                {{ btnRadius.split("px")[1] == "" ? null : "px" }}
            </div>
        </div>
        <!-- Button size  -->
        <div class="edit-row range">
            <div class="title">
                <label for="btnSize">Button size</label>
                <input
                    id="btnSize"
                    type="range"
                    min="40"
                    max="70"
                    step="5"
                    v-model="btnSize"
                    @input="updateBtnSize(btnSize)"
                />
            </div>
            <div class="toggle">
                {{ btnSize }}
                {{ btnSize.split("px")[1] == "" ? null : "px" }}
            </div>
        </div>

        <!-- The Button -->
        <!-- <div class="edit-row edit-input-button mt-3">
            <div class="input">
                <label for="apply-button">Button</label>
                <div class="edit-button-container">
                    <button
                        :style="[
                            btnColorStyle,
                            btnBorderStyle,
                            btnTxtColorStyle,
                            btnRadiusStyle,
                            btnSizeStyle
                        ]"
                        class="btn-embed"
                        id="apply-button"
                    >
                        {{ this.btnTxt }}
                    </button>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
import clickOutside from "../../../directive.js";
import Vue from "vue";
import CancelColorButton from "../../utils/CancelColorButton";
import { Chrome } from "vue-color";
import Tooltip from "../../utils/Tooltip";

export default {
    components: {
        "chrome-picker": Chrome,
        Tooltip,
        CancelColorButton
    },
    props: {
        styles: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            //btn Color
            isBtnColorActive: false,
            btnColorCancelButton: false,
            btnColor: {
                hex: this.styles.btnColor
            },
            btnOriginalColor: {
                hex: this.styles.btnColor
            },
            // btn border color
            isBtnBorderActive: false,
            btnBorderColorCancelButton: false,
            btnBorderColor: {
                hex: this.styles.btnBorderColor
            },
            btnBorderOriginalColor: {
                hex: this.styles.btnBorderColor
            },
            // btn text color
            isBtnTxtColorActive: false,
            btnTxtColorCancelButton: false,
            btnTxtColor: {
                hex: this.styles.btnTxtColor
            },
            btnTxtOriginalColor: {
                hex: this.styles.btnTxtColor
            },
            //btn radius, size and text
            btnRadius: this.styles.btnRadius.split("px")[0],
            btnSize: this.styles.btnSize.split("px")[0],
            btnTxt: this.styles.btnTxt,
            formId: this.styles.formId
        };
    },
    watch: {
        styles(newValue, oldValue) {
            this.btnColor.hex = newValue.btnColor;
            this.btnBorderColor.hex = newValue.btnBorderColor;
            this.btnTxtColor.hex = newValue.btnTxtColor;
            this.btnRadius = newValue.btnRadius.split("px")[0];
            this.btnSize = newValue.btnSize.split("px")[0];
            this.btnTxt = newValue.btnTxt;
        }
    },
    methods: {
        mountCancelButton: function(btnIndex, btnAction) {
            var ComponentClass = Vue.extend(CancelColorButton);
            var instance = new ComponentClass({
                propsData: { action: btnAction }
            });
            instance.$mount();

            let pickerBody = document.getElementsByClassName("vc-chrome-body")[
                btnIndex
            ];
            pickerBody.appendChild(instance.$el);
        },
        // btn color
        openBtnColorPicker: function() {
            if (!this.isBtnColorActive) {
                this.isBtnColorActive = true;

                if (!this.btnColorCancelButton) {
                    this.btnColorCancelButton = true;
                    this.mountCancelButton(6, this.cancelBtnColor);
                }
            }
        },
        cancelBtnColor: function() {
            this.btnColor = this.btnOriginalColor.hex;
            this.styles.btnColor = this.btnOriginalColor.hex;
        },
        closeBtnColorPicker: function() {
            this.isBtnColorActive = false;
        },
        updateBtnColor(value) {
            this.styles.btnColor = value.hex;
        },
        // btn border color
        openBtnBorderColorPicker: function() {
            if (!this.isBtnBorderActive) {
                this.isBtnBorderActive = true;

                if (!this.btnBorderColorCancelButton) {
                    this.btnBorderColorCancelButton = true;
                    this.mountCancelButton(7, this.cancelBtnBorderColor);
                }
            }
        },
        cancelBtnBorderColor: function() {
            this.btnBorderColor = this.btnBorderOriginalColor.hex;
            this.styles.btnBorderColor = this.btnBorderOriginalColor.hex;
        },
        closeBtnBorderPicker: function() {
            this.isBtnBorderActive = false;
        },
        updateBtnBorder(value) {
            this.styles.btnBorderColor = value.hex;
        },
        // btn text color
        openBtnTxtColorPicker: function() {
            if (!this.isBtnTxtColorActive) {
                this.isBtnTxtColorActive = true;

                if (!this.btnTxtColorCancelButton) {
                    this.btnTxtColorCancelButton = true;
                    this.mountCancelButton(8, this.cancelBtnTxtColor);
                }
            }
        },
        cancelBtnTxtColor: function() {
            this.btnTxtColor = this.btnTxtOriginalColor.hex;
            this.styles.btnTxtColor = this.btnTxtOriginalColor.hex;
        },
        closeBtnTxtColorPicker: function() {
            this.isBtnTxtColorActive = false;
        },
        updateBtnTxtColor(value) {
            this.styles.btnTxtColor = value.hex;
        },
        updateBtnRadius(value) {
            this.styles.btnRadius = value + "px";
        },
        updateBtnSize(value) {
            this.styles.btnSize = value + "px";
        },
        updateBtnTxt(value) {
            this.styles.btnTxt = value;
        }
    },
    computed: {
        btnColorStyle: function() {
            return {
                "--btn-color": this.btnColor.hex
            };
        },
        btnBorderStyle: function() {
            return {
                "--btn-border-color": this.btnBorderColor.hex
            };
        },
        btnTxtColorStyle: function() {
            return {
                "--btn-txt-color": this.btnTxtColor.hex
            };
        },
        btnRadiusStyle: function() {
            return {
                "--btn-radius": this.btnRadius + "px"
            };
        },
        btnSizeStyle: function() {
            return {
                "--btn-size": this.btnSize + "px"
            };
        }
    }
};
</script>

<style lang="scss">
@import "../../../../../resources/sass/_variables.scss";

.embed-button {
    display: flex;
    align-items: center;
    color: $greyHover;
    p {
        margin-bottom: 0px;
        margin-right: 5px;
    }
}
.btn-color {
    background-color: var(--btn-color);
}
.btn-border-color {
    background-color: var(--btn-border-color);
}
.btn-txt-color {
    background-color: var(--btn-txt-color);
}
.btn-embed {
    border: 2px solid;
    width: calc(var(--btn-size) * 27 / 6);
    height: var(--btn-size);
    border-radius: var(--btn-radius);
    background-color: var(--btn-color);
    border-color: var(--btn-border-color);
    color: var(--btn-txt-color);
}
.edit-button-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    button {
        font-size: 1rem;
    }
}
</style>
