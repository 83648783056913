// initial state
const state = () => ({
    successAlert: false
});

const getters = {};

const actions = {
    updateAlert(context) {
        context.commit("updateAler");
    }
};

const mutations = {
    updateAler(state) {
        state.successAlert = !state.successAlert;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
