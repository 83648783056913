var template = `<div class="lc-container" style="padding: 0 15px;" >

  <div class="lc-logo" v-show="showLogo && !this.styles.singleLender.is_active">
    <img :src="this.styles.logoUrl" id="logo-template" @error="imageLoadError">
  </div>

  <div class="lc-single-lender" v-show="this.styles.singleLender.is_active">
    <img :src="singleLenderLogo" id="logo-template">
    <h5 class="lc-input-label">Check your eligibility with {{this.styles.singleLender.name}} & other lenders</h5>
  </div>

  <div class="lc-text-header" v-show="!this.styles.singleLender.is_active">
    <h1>
      {{title1}}
    </h1>
  </div>

  <div class="lc-from-container">
      <label for="loan-amount" class="lc-input-label">Loan Amount</label>
      <currency-input 
        id="loan-amount"
        class="lc-input"
        v-model.trim="$v.loanAmount.$model"
        :class="{ 'lc-input-error': $v.loanAmount.$error }"
        placeholder="$"
        currency="USD"
        :distraction-free=false
      />
      <small class="lc-error-helper" v-if="$v.loanAmount.$error">Loan Amount is required</small>

      <div v-show="this.styles.showLoanType">
        <label class="lc-input-label">Loan Purpose</label>

        <div class="lc-button-options-container template-desktop" >
          <loan-purpose v-for="(item, index) in this.styles.loanTypeSelected"
          v-if="item.selected"
          :type="item.type"
          :icon="item.icon"
          :key="index"
          v-model.trim="$v.loanPurpose.$model"
          ></loan-purpose>
        </div>

        <select class="lc-select template-mobile" v-model.trim="$v.loanPurpose.$model" :class="{ 'lc-input-error': $v.loanPurpose.$error }" >
              <option selected disabled value="">Select</option>
              <option v-for="(item, index) in this.styles.loanTypeSelected" v-if="item.selected" value="item.type">{{item.type}}</option>
        </select>

        <small class="lc-error-helper" v-if="$v.loanPurpose.$error">Please select a Loan Purpose</small>
      </div>

      <div class="lc-first-last" >
        <div class="lc-first-last-width">
          <label for="first-name" class="lc-input-label">First Name</label>
          <input
              type="text"
              id="first-name"
              v-model.trim="$v.firstName.$model"
              :class="{ 'lc-input-error': $v.firstName.$error }"
          />
          <small class="lc-error-helper" v-if="$v.firstName.$error && $v.firstName.minLength">First Name is required</small>
          <small class="lc-error-helper" v-if="!$v.firstName.minLength">First Name must have at least {{$v.firstName.$params.minLength.min}} letters.</small>
        </div>

        <div class="lc-first-last-width">
          <label for="last-name" class="lc-input-label">Last Name</label>
          <input
              type="text"
              id="last-name"
              v-model.trim="$v.lastName.$model"
              :class="{ 'lc-input-error': $v.lastName.$error }"
          />
          <small class="lc-error-helper" v-if="$v.lastName.$error && $v.lastName.minLength">Last Name is required</small>
          <small class="lc-error-helper" v-if="!$v.lastName.minLength">Last Name must have at least {{$v.lastName.$params.minLength.min}} letters.</small>
        </div>
      </div>

      <label for="email" class="lc-input-label">Email</label>
      <input
          type="email"
          id="email"
          v-model="email"
          v-model.trim="$v.email.$model"
          :class="{ 'lc-input-error': $v.email.$error }"
      />
      <small class="lc-error-helper" v-if="$v.email.$error && $v.email.email">Email is Required</small>
      <small class="lc-error-helper" v-if="!$v.email.email">Must be an email</small>

      <label class="lc-input-label">Date of Birth</label>
      <div style="display: flex; justify-content: space-between;">
        <div style="width: 30%">
          <select id="dob-month" class="lc-select" v-model.trim="$v.dobMonth.$model"
          :class="{ 'lc-input-error': $v.dobMonth.$error }" >
              <option selected disabled value="">Month</option>
              <option value="01">Jan</option>
              <option value="02">Feb</option>
              <option value="03">Mar</option>
              <option value="04">Apr</option>
              <option value="05">May</option>
              <option value="06">Jun</option>
              <option value="07">Jul</option>
              <option value="08">Aug</option>
              <option value="09">Sep</option>
              <option value="10">Oct</option>
              <option value="11">Nov</option>
              <option value="12">Dec</option>
          </select>
        </div>

        <div style="width: 30%">
          <select id="dob-day" class="lc-select" v-model.trim="$v.dobDay.$model"
          :class="{ 'lc-input-error': $v.dobDay.$error }">
              <option selected disabled value="">Day</option>
              <option v-for="day in optionsDobDays" :value="day">{{day}}</option>
              
          </select>
        </div>
        <div style="width: 30%">
          <select id="dob-year" class="lc-select" v-model.trim="$v.dobYear.$model"
          :class="{ 'lc-input-error': $v.dobYear.$error }">
            <option selected disabled value="">Year</option>
            <option v-for="year in optionsDobYears" :value="year">{{year}}</option>
          </select>
        </div>
      </div>
      <small class="lc-error-helper" v-if="$v.dobMonth.$error || $v.dobDay.$error || $v.dobYear.$error">Date of Birth</small>

      <label for="phone" class="lc-input-label">Phone Number</label>
      <vue-tel-input
          v-bind="phoneProps"
          class="lc-input"
          id="phone"
          v-model.trim="$v.phone.$model"
          :class="{ 'lc-input-error': $v.phone.$error }"
      />
      <small class="lc-error-helper" v-if="$v.phone.$error">Phone Number is Required</small>
      <small class="lc-helper">We use your phone number to help verify your identity.</small>

      <div>
        <label for="autocompleteAddress" class="lc-input-label">Street Address</label>
        <input
            type="text"
            id="autocompleteAddress"
            onFocus="this.geolocate"
            v-model.trim="$v.address.$model"
            :class="{ 'lc-input-error': $v.address.$error }"
            aria-describedby="addressManually"
            placeholder=" "
        />
        <small class="lc-error-helper" v-if="$v.address.$error">Street Address is Required</small><br/>

        <label class="lc-input-label">Unit</label>
        <input
            type="text"
            v-model="unit"
            placeholder="optional"
        />

        <label for="city" class="lc-input-label">City</label>
        <input
            type="text"
            id="city"
            v-model="city"
            v-model.trim="$v.city.$model"
            :class="{ 'lc-input-error': $v.city.$error }"
        />
        <small class="lc-error-helper" v-if="$v.city.$error">City is Required</small>

        <div style="display: flex; justify-content: space-between; margin-bottom: 20px;">
          <div style="width: 47%">
            <label for="province" class="lc-input-label">Province</label>
            <select id="province" class="lc-select" v-model.trim="$v.province.$model"
            :class="{ 'lc-input-error': $v.province.$error }">
                <option selected disabled value="">Select</option>
                <option value="AB">Alberta</option>
                <option value="BC">British Columbia</option>
                <option value="MB">Manitoba</option>
                <option value="NB">New Brunswick</option>
                <option value="NL">Newfoundland &amp; Labrador</option>
                <option value="NS">Nova Scotia</option>
                <option value="NT">Northwest Territories</option>
                <option value="NU">Nunavut</option>
                <option value="ON">Ontario</option>
                <option value="PE">Prince Edward Island</option>
                <option value="QC">Quebec</option>
                <option value="SK">Saskatchewan</option>
                <option value="YT">Yukon</option>
            </select>
            <small class="lc-error-helper" v-if="$v.province.$error">Province is Required</small>
          </div>
          <div style="width: 47%">
            <label for="postal-code" class="lc-input-label">Postal Code</label>
            <input
                type="text"
                id="postal-code"
                v-model.trim="$v.postalCode.$model"
                :class="{ 'lc-input-error': $v.postalCode.$error }"
            />
            <small class="lc-error-helper" v-if="$v.postalCode.$error && $v.postalCode.minLength">Postal Code is Required</small>
            <small class="lc-error-helper" v-if="!$v.postalCode.minLength">Postal Code must have {{$v.postalCode.$params.minLength.min}} characters.</small>
          </div>
        </div>
      </div>

      <label for="previous-address" class="lc-input-label" style="line-height: 1.6">
      Have you been living in your current address for less than three (3) years? 
      </label>
      <div style="display: flex; margin-top: 10px;">
        <div style="display: flex; align-items: baseline;">
          <label class="lc-radio"> Yes
            <input type="radio" :value="true" v-model="hasPreviousAddress">
            <span class="checkmark"></span>
          </label>
        </div>
        <div style="display: flex; align-items: baseline; margin-left: 20px; ">
          <label class="lc-radio"> No
            <input type="radio" :value="false" v-model="hasPreviousAddress">
            <span class="checkmark"></span>
          </label>
        </div>
      </div>

      <div v-show="hasPreviousAddress">
        <label  class="lc-input-label">Previous Street Address</label>
        <input
            type="text"
            id="autocompleteAddressPrev"
            onFocus="this.geolocate"
            v-model="prev_address"
            placeholder=" "
            aria-describedby="prevAddressManually"
        />

        <label for="prev-unit" class="lc-input-label">Unit</label>
        <input
            type="text"
            id="prev-unit"
            v-model="prev_unit"
            placeholder="optional"
        />
        <label for="prev-city" class="lc-input-label">City</label>
        <input
            type="text"
            id="prev-city"
            v-model="prev_city"
        />

        <div style="display: flex; justify-content: space-between; margin-bottom: 20px;">
          <div style="width: 47%">
            <label for="province" class="lc-input-label">Province</label>
            <select id="province" class="lc-select" v-model="prev_province">
                <option selected disabled value="">Select</option>
                <option value="AB">Alberta</option>
                <option value="BC">British Columbia</option>
                <option value="MB">Manitoba</option>
                <option value="NB">New Brunswick</option>
                <option value="NL">Newfoundland &amp; Labrador</option>
                <option value="NS">Nova Scotia</option>
                <option value="NT">Northwest Territories</option>
                <option value="NU">Nunavut</option>
                <option value="ON">Ontario</option>
                <option value="PE">Prince Edward Island</option>
                <option value="QC">Quebec</option>
                <option value="SK">Saskatchewan</option>
                <option value="YT">Yukon</option>
            </select>
          </div>
          <div style="width: 47%">
            <label for="prev-postal-code" class="lc-input-label">Postal Code</label>
            <input
                type="text"
                id="prev-postal-code"
                v-model="prev_postalCode"
            />
          </div>
        </div>
      </div>

      <div class="lc-checkboxes">
        <label class="toggle">
          <input class="toggle-checkbox" type="checkbox" v-model="check1">
          <div class="toggle-switch"></div>
        </label>
        <p>I accept and agree to the LoanConnect Terms and Conditions, and Privacy Policy </p>
      </div>

      <div class="lc-checkboxes">
        <label class="toggle">
          <input class="toggle-checkbox" type="checkbox" v-model="check2">
          <div class="toggle-switch"></div>
        </label>
        <p>
          Yes, I would like LoanConnect.ca to send me electronic newsletters and special offers to get access to relevant articles, news, personal finance tips and receive loan offers from LoanConnect's lender network. I am able to unsubscribe at any time. For details, see our Privacy Policy.
        </p>
      </div>

      <span class="lc-form-container">
        <button class="lc-btn lc-btn-next lc-btn-block" @click="validateForm(1)">
            I agree to all
        </button>

        <button class="lc-btn lc-btn-text lc-btn-block" @click="validateForm(1)">
        Just look for a Loan
        </button>
      </span>


  </div>
</div>
`;
import CreditScore from "./components/CreditScore.js";
import LoanPurpose from "./components/LoanPurpose.js";
import LoanOption from "./components/LoanOption.js";
import { VueTelInput } from "vue-tel-input";
const { required, minLength, email } = require("vuelidate/lib/validators");

export default {
    template: template,
    components: {
        CreditScore,
        LoanPurpose,
        LoanOption,
        VueTelInput
    },
    props: {
        styles: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            optionsDobDays: [],
            optionsDobYears: [],
            hasPreviousAddress: "",
            check1: false,
            check2: false,
            // 1 slide
            loanAmount: null,
            loanPurpose: "",
            firstName: "",
            lastName: "",
            email: "",
            dobMonth: "",
            dobDay: "",
            dobYear: "",
            phone: "",
            address: "",
            unit: "",
            city: "",
            province: "",
            postalCode: "",
            // employment: "",
            // annualIncome: null,
            // Previous Address
            prev_unit: "",
            prev_address: "",
            prev_unit: "",
            prev_city: "",
            prev_province: "",
            prev_postalCode: "",
            //Phone props
            phoneProps: {
                autocomplete: "off",
                defaultCountry: "CA",
                disabledFetchingCountry: true,
                placeholder: "Enter a phone number",
                enabledFlags: true,
                onlyCountries: ["CA"],
                validCharactersOnly: true
            }
        };
    },
    created() {
        // Create the array with years and days
        for (let index = 2002; index >= 1900; index--) {
            this.optionsDobYears.push(index);
        }
        for (let index = 1; index <= 31; index++) {
            if (index < 10) index = "0" + index;
            this.optionsDobDays.push(index);
        }
    },
    mounted() {
        this.initAutocomplete();
        this.initAutocompletePrev();
    },
    computed: {
        showLogo: function() {
            return this.styles.showLogo;
        },
        singleLenderLogo: function() {
            if (this.styles.singleLender.is_active) {
                return (
                    "https://loanconnect.ca/img/" +
                    this.styles.singleLender.logo
                );
            }
        },
        title1: function() {
            return this.styles.title1;
        }
    },
    methods: {
        initAutocomplete() {
            // Create the autocomplete object, restricting the search predictions to
            // geographical location types.
            this.autocomplete = new google.maps.places.Autocomplete(
                document.getElementById("autocompleteAddress"),
                { types: ["geocode"] }
            );

            // Set the autocomplete to search only for Canadian addresses
            this.autocomplete.setComponentRestrictions({
                country: ["ca"]
            });
            // Avoid paying for data that you don't need by restricting the set of
            // place fields that are returned to just the address components.
            this.autocomplete.setFields(["address_component"]);
            // When the user selects an address from the drop-down, populate the
            // address fields in the form.
            this.autocomplete.addListener("place_changed", this.fillInAddress);
        },
        initAutocompletePrev() {
            // Create the autocomplete object, restricting the search predictions to
            // geographical location types.
            this.autocompletePrev = new google.maps.places.Autocomplete(
                document.getElementById("autocompleteAddressPrev"),
                { types: ["geocode"] }
            );
            // Set the autocomplete to search only for Canadian addresses
            this.autocompletePrev.setComponentRestrictions({
                country: ["ca"]
            });
            // Avoid paying for data that you don't need by restricting the set of
            // place fields that are returned to just the address components.
            this.autocompletePrev.setFields(["address_component"]);
            // When the user selects an address from the drop-down, populate the
            // address fields in the form.
            this.autocompletePrev.addListener(
                "place_changed",
                this.fillInAddressPrev
            );
        },
        fillInAddress() {
            // Get the place details from the autocomplete object.
            const place = this.autocomplete.getPlace();
            const address_components = place.address_components || {};
            this.address =
                this.extractFromAddress(address_components, "street_number") +
                " " +
                this.extractFromAddress(address_components, "route");
            this.city = this.extractFromAddress(address_components, "locality");
            this.province = this.extractFromAddress(
                address_components,
                "administrative_area_level_1"
            );
            this.postalCode = this.extractFromAddress(
                address_components,
                "postal_code"
            );
        },
        fillInAddressPrev() {
            // Get the place details from the autocomplete object.
            const place = this.autocompletePrev.getPlace();
            const address_components = place.address_components || {};
            this.prev_address =
                this.extractFromAddress(address_components, "street_number") +
                " " +
                this.extractFromAddress(address_components, "route");
            this.prev_city = this.extractFromAddress(
                address_components,
                "locality"
            );
            this.prev_province = this.extractFromAddress(
                address_components,
                "administrative_area_level_1"
            );
            this.prev_postalCode = this.extractFromAddress(
                address_components,
                "postal_code"
            );
        },
        extractFromAddress(components, type) {
            return (
                components
                    .filter(component => component.types.indexOf(type) === 0)
                    .map(item => item.short_name)
                    .pop() || ""
            );
        },
        geolocate() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(position => {
                    const geolocation = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    };
                    const circle = new google.maps.Circle({
                        center: geolocation,
                        radius: position.coords.accuracy
                    });
                    this.autocomplete.setBounds(circle.getBounds());
                    this.autocompletePrev.setBounds(circle.getBounds());
                });
            }
        },
        validateForm(callNumber) {
            const fieldsTovalidate = Object.keys(this.$v.$params);
            const invalidField = fieldsTovalidate.filter(
                field => this.$v[field].$invalid
            );
            // Check for mandatory fields
            if (invalidField.length > 0) {
                this.$v.$touch();
                // Scroll to the top
                window.scrollTo(0, 0);
            }
        },
        imageLoadError() {}
    },
    validations: {
        loanAmount: {
            required
        },
        loanPurpose: {
            required
        },
        firstName: {
            required,
            minLength: minLength(2)
        },
        lastName: {
            required,
            minLength: minLength(2)
        },
        email: {
            required,
            email
        },
        phone: {
            required
        },
        dobMonth: {
            required
        },
        dobDay: {
            required
        },
        dobYear: {
            required
        },
        address: {
            required
        },
        city: {
            required
        },
        province: {
            required
        },
        postalCode: {
            required,
            minLength: minLength(6)
        }
        // Second set of data
        // citizenship: {
        //     required
        // },
        // employment: {
        //     required
        // },
    }
};
