var template = `
<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">

		<path d="M502,492h-22V10c0-5.522-4.478-10-10-10h-14.57c-3.118,0-6.058,1.454-7.949,3.933L344.808,138.46l-34.335-54.771
			c-1.75-2.792-4.763-4.542-8.055-4.68c-3.272-0.13-6.44,1.354-8.418,3.991L172.813,244.583l-34.691-15.694
			c-3.67-1.659-7.976-0.957-10.927,1.783l-83.892,77.899L20,328.962V10c0-5.522-4.478-10-10-10S0,4.478,0,10v492
			c0,5.522,4.478,10,10,10h492c5.522,0,10-4.478,10-10C512,496.478,507.522,492,502,492z M460,492H20V355.537l36.585-32.012
			c0.074-0.064,0.147-0.131,0.22-0.197l79.129-73.478l35.944,16.261c4.272,1.931,9.31,0.638,12.122-3.111l117.262-156.35
			l34.266,54.661c1.742,2.779,4.736,4.527,8.013,4.678c3.289,0.147,6.419-1.314,8.409-3.922L460,20.494V492z"/>

		<path d="M176,309c-5.51,0-10,4.49-10,10s4.49,10,10,10s10-4.49,10-10S181.51,309,176,309z"/>

		<path d="M176,349c-5.51,0-10,4.49-10,10v103c0,5.51,4.49,10,10,10s10-4.49,10-10V359C186,353.49,181.51,349,176,349z"/>

		<path d="M302,186c-5.51,0-10,4.49-10,10v266c0,5.51,4.49,10,10,10s10-4.49,10-10V196C312,190.49,307.51,186,302,186z"/>

		<path d="M302,146c-5.51,0-10,4.479-10,10c0,5.51,4.49,10,10,10s10-4.49,10-10C312,150.479,307.51,146,302,146z"/>

		<path d="M428,100c-5.51,0-10,4.49-10,10s4.49,10,10,10s10-4.49,10-10S433.51,100,428,100z"/>

		<path d="M428,140c-5.51,0-10,4.49-10,10v312c0,5.51,4.49,10,10,10s10-4.49,10-10V150C438,144.49,433.51,140,428,140z"/>
</svg>

`;

export default {
    template: template,
};
