var template = `
<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">

		<path d="M488,40H24C10.745,40,0,50.745,0,64v304c0,13.255,10.745,24,24,24h200v-16H24c-4.418,0-8-3.582-8-8v-16h208v-16H16V64
			c0-4.418,3.582-8,8-8h464c4.418,0,8,3.582,8,8v168h16V64C512,50.745,501.255,40,488,40z"/>

		<path d="M112,80H48c-4.418,0-8,3.582-8,8v64c0,4.418,3.582,8,8,8h64c4.418,0,8-3.582,8-8V88C120,83.582,116.418,80,112,80z
			 M104,144H56V96h48V144z"/>

		<path d="M432,80c-22.091,0-40,17.909-40,40s17.909,40,40,40c22.08-0.026,39.974-17.92,40-40C472,97.909,454.091,80,432,80z
			 M432,144c-13.255,0-24-10.745-24-24s10.745-24,24-24s24,10.745,24,24S445.255,144,432,144z"/>

		<path d="M288,208v-16H48c-4.418,0-8,3.582-8,8v64c0,4.418,3.582,8,8,8h200v-16H56v-48H288z"/>

		<rect x="40" y="296" width="96" height="16"/>

		<rect x="152" y="296" width="16" height="16"/>

		<path d="M384,216c-70.692,0-128,57.308-128,128s57.308,128,128,128c70.658-0.084,127.916-57.342,128-128
			C512,273.308,454.692,216,384,216z M384,456c-61.856,0-112-50.144-112-112s50.144-112,112-112
			c61.828,0.066,111.934,50.172,112,112C496,405.856,445.856,456,384,456z"/>

		<path d="M450.344,290.344L352,388.688l-34.344-34.344l-11.312,11.312l40,40c1.5,1.5,3.534,2.344,5.656,2.344
			s4.156-0.844,5.656-2.344l104-104L450.344,290.344z"/>
</svg>
`;

export default {
    template: template,
};
