var template = `
    <div class="lc-select-option" :class="{'lc-option-selected' : this.loanSelected === this.type}"  @click="selectLoanPurpose" @keydown.space="selectLoanPurpose">
        <div class="lc-icon">
            <component :is="this.icon"></component>
        </div>
        <div class="lc-purpose-name">
            {{ this.type }}
        </div>
    </div>
`;

import {
    debt,
    creditCard,
    car,
    home,
    business,
    medical,
    bills,
    wedding,
    vacation,
    rebuild,
    purchase,
    other,
} from './icons/index.js';

export default {
    template: template,
    components: {
        debt,
        creditCard,
        car,
        home,
        business,
        medical,
        bills,
        wedding,
        vacation,
        rebuild,
        purchase,
        other,
    },
    model: {
        prop: 'loanSelected',
        event: 'onUpdate',
    },
    props: ['type', 'icon', 'loanSelected'],
    methods: {
        selectLoanPurpose() {
            this.$emit('onUpdate', this.type);
        },
    },
};
