<template>
    <div class="p-5 row justify-content-md-center">
        <form action="#" @submit.prevent="login">
            <div class="form-group col-md-auto">
                <input
                    type="email"
                    id="inputEmail"
                    placeholder="Email"
                    required
                    class="form-control"
                    name="email"
                    v-model="email"
                />
                <br />
                <input
                    type="password"
                    id="inputPassword"
                    placeholder="Password"
                    required
                    class="form-control"
                    v-model="password"
                />
                <button class="btn btn-success btn-block mt-3" type="submit">
                    Login
                </button>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    name: "login",
    props: {
        dataSuccessMessage: {
            type: String
        }
    },
    data() {
        return {
            email: "",
            password: "",
            loading: false
        };
    },
    methods: {
        login() {
            this.loading = true;
            this.$store
                .dispatch("auth/retrieveToken", {
                    email: this.email,
                    password: this.password
                })
                .then(response => {
                    this.loading = false;
                    this.$router.push({ name: "forms" });
                })
                .catch(error => {
                    this.loading = false;
                    this.password = "";
                    this.successMessage = "";
                });
        }
    }
};
</script>

<style lang="scss"></style>
