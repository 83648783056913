<template>
    <span
        class="lc-tooltip"
        v-tooltip.auto="{
            content: msg,
            class: 'tooltip-custom'
        }"
        >?</span
    >
</template>

<script>
export default {
    name: "tooltip",
    props: {
        msg: {
            type: String,
            required: true
        }
    }
};
</script>

<style lang="scss">
@import "../../../../resources/sass/_variables.scss";

.lc-tooltip {
    background-color: $grey;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.7rem;
    font-weight: bold;
    cursor: pointer;
}
</style>
