var template = `
    <div class="lc-select-option" :class="{'lc-option-selected' : this.scoreSelected === this.name}" tabindex="0" @click="selectCreditScore" @keydown.space="selectCreditScore">
      <div class="lc-score-name">{{ this.name }}</div>
      <div class="lc-score-range">{{ this.range }}</div>
    </div>
`;

export default {
    template: template,
    model: {
        prop: 'scoreSelected',
        event: 'onUpdate',
    },
    props: ['name', 'range', 'scoreSelected'],
    methods: {
        selectCreditScore() {
            this.$emit('onUpdate', this.name);
        },
    },
};
