<template>
    <div class="lc-alert-success">Updated Successfully</div>
</template>

<script>
export default {
    name: "alert-success",
    created() {
        setTimeout(() => {
            this.$store.dispatch("ui/updateAlert");
        }, 3000);
    }
};
</script>

<style>
.lc-alert-success {
    color: #1e643b;
    background-color: #d7f3e3;
    border-color: #c8eed7;
    border-radius: 5px;
    position: fixed;
    font-size: 1.3rem;
    padding: 18px;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.3) !important;
    border-radius: 0.25rem;
    top: 10px;
    right: 10px;
    z-index: 99;
}
</style>
