<template>
    <div id="spinner-wrapper">
        <div class="profile-main-spinner">
            <div class="spinner-loader">
                <svg class="circular-loader" viewBox="25 25 50 50">
                    <circle
                        class="spinner-path"
                        cx="50"
                        cy="50"
                        r="20"
                        fill="none"
                        stroke="#fff"
                        stroke-width="4"
                    />
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "spinner"
};
</script>

<style>
#spinner-wrapper {
    display: flex;
    justify-content: center;
}
.profile-main-spinner {
    width: 45px;
}

.profile-main-spinner .spinner-loader {
    position: relative;
    width: 40px;
    height: 40px;
}
.profile-main-spinner .spinner-loader:before {
    content: "";
    display: block;
    padding-top: 100%;
}

.circular-loader {
    -webkit-animation: rotate 2s linear infinite;
    animation: rotate 2s linear infinite;
    height: 100%;
    -webkit-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    margin: auto;
}

.spinner-path {
    stroke-dasharray: 150, 200;
    stroke-dashoffset: -10;
    -webkit-animation: dash 1.5s ease-in-out infinite,
        color 6s ease-in-out infinite;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
}

@-webkit-keyframes rotate {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotate {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@-webkit-keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124;
    }
}
@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124;
    }
}
</style>
