var template = `
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
		<path id="XMLID_832_" d="M463.099,224.826L454.423,199h11.702c16.542,0,30-13.458,30-30s-13.458-30-30-30h-31.86l-26.203-77.994    C401.229,40.666,382.222,27,360.765,27H253.238c-5.522,0-10,4.477-10,10s4.478,10,10,10h107.526    c12.856,0,24.244,8.188,28.339,20.375L440.043,219h-25.318L367.293,77.815c-1.368-4.072-5.184-6.815-9.479-6.815h-67.476    c-0.003,0-0.007,0-0.007,0H150.615c-4.296,0-8.111,2.743-9.479,6.815L93.703,219H68.386l50.939-151.625    C123.42,55.188,134.808,47,147.664,47h25.57c5.522,0,10-4.477,10-10s-4.478-10-10-10h-25.57    c-21.457,0-40.464,13.666-47.298,34.006L74.164,139H44.875c-16.542,0-30,13.458-30,30s13.458,30,30,30h9.131l-9.009,26.815    c-0.091,0.272-0.156,0.549-0.223,0.825C18.322,239.277,0,266.29,0,297.508v53.5c0,34.929,25.002,64.118,58.048,70.638v31.664    c0,17.474,14.216,31.69,31.69,31.69h20.619c17.475,0,31.69-14.216,31.69-31.69v-30.294h227.904v30.294    c0,17.474,14.216,31.69,31.69,31.69h20.619c17.475,0,31.69-14.216,31.69-31.69v-31.664c33.045-6.52,58.048-35.709,58.048-70.638    v-53.5C512,264.693,491.751,236.534,463.099,224.826z M466.125,159c5.514,0,10,4.486,10,10s-4.486,10-10,10h-18.421l-6.719-20    H466.125z M274.613,219l89.084-89.085L393.626,219H274.613z M157.805,91H266.19l-70.929,70.929    c-3.905,3.905-3.905,10.237,0,14.143c1.953,1.953,4.512,2.929,7.071,2.929s5.118-0.976,7.071-2.929L294.476,91h56.148    l5.961,17.743L246.328,219H114.802L157.805,91z M44.875,179c-5.514,0-10-4.486-10-10s4.486-10,10-10h22.569l-6.719,20H44.875z     M122.048,453.31c0,6.446-5.244,11.69-11.69,11.69H89.738c-6.446,0-11.69-5.244-11.69-11.69v-30.294h44V453.31z M433.952,453.31    c0,6.446-5.244,11.69-11.69,11.69h-20.619c-6.446,0-11.69-5.244-11.69-11.69v-30.294h44V453.31z M492,351.008    c0,28.677-23.331,52.008-52.008,52.008H72.008C43.331,403.016,20,379.685,20,351.008v-53.5c0-32.253,26.239-58.492,58.492-58.492    h355.016c32.253,0,58.492,26.239,58.492,58.492V351.008z"/>
		<path id="XMLID_1064_" d="M213.24,47c2.63,0,5.21-1.07,7.069-2.93c1.86-1.86,2.931-4.44,2.931-7.07c0-2.63-1.07-5.21-2.931-7.07    c-1.87-1.86-4.439-2.93-7.069-2.93c-2.631,0-5.211,1.07-7.07,2.93c-1.87,1.86-2.93,4.44-2.93,7.07c0,2.63,1.06,5.21,2.93,7.07    C208.029,45.93,210.609,47,213.24,47z"/>
		<path id="XMLID_1092_" d="M318.758,283.016H193.242c-15.021,0-27.242,12.221-27.242,27.242v21.516    c0,15.021,12.221,27.242,27.242,27.242h125.516c15.021,0,27.242-12.221,27.242-27.242v-21.516    C346,295.236,333.779,283.016,318.758,283.016z M266,303.016h20v36h-20V303.016z M246,339.016h-20v-36h20V339.016z M186,331.773    v-21.516c0-3.993,3.249-7.242,7.242-7.242H206v36h-12.758C189.249,339.016,186,335.767,186,331.773z M326,331.773    c0,3.993-3.249,7.242-7.242,7.242H306v-36h12.758c3.993,0,7.242,3.249,7.242,7.242V331.773z"/>
		<path id="XMLID_1106_" d="M173.09,188.24c-2.63,0-5.21,1.07-7.07,2.93c-1.859,1.86-2.93,4.44-2.93,7.07s1.07,5.21,2.93,7.07    c1.86,1.86,4.44,2.93,7.07,2.93s5.21-1.07,7.07-2.93c1.859-1.86,2.93-4.44,2.93-7.07s-1.07-5.21-2.93-7.07    C178.3,189.31,175.729,188.24,173.09,188.24z"/>
		<path id="XMLID_1150_" d="M96.048,273.016c-26.468,0-48,21.533-48,48s21.532,48,48,48s48-21.533,48-48    S122.516,273.016,96.048,273.016z M96.048,349.016c-15.439,0-28-12.561-28-28s12.561-28,28-28s28,12.561,28,28    S111.487,349.016,96.048,349.016z"/>
		<path id="XMLID_1153_" d="M415.952,273.016c-26.468,0-48,21.533-48,48s21.532,48,48,48s48-21.533,48-48    S442.42,273.016,415.952,273.016z M415.952,349.016c-15.439,0-28-12.561-28-28s12.561-28,28-28s28,12.561,28,28    S431.392,349.016,415.952,349.016z"/>
</svg>
`;

export default {
    template: template,
};
