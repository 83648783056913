<template>
    <div
        class="form-item"
        @mouseover="showActions = true"
        @mouseout="showActions = false"
    >
        <div class="infoCard">
            <div class="name-date">
                <div class="name">{{ form.name }}</div>
                <div class="date">
                    {{ form.created_at | moment("MMM DD, YYYY") }}
                </div>
            </div>
            <div class="actions">
                <!-- <div> -->
                <div v-show="showActions" class="show-actions">
                    <a
                        :href="`/form/${form.id}`"
                        class="action-icon"
                        @click="setLocalStorage('Form')"
                    >
                        <EditIcon />
                    </a>
                    <a href="#" class="action-icon" @click="showCopyModal()">
                        <CopyIcon />
                    </a>
                    <a href="#" class="action-icon" @click="showDeleteModal()">
                        <DeleteIcon />
                    </a>
                </div>
            </div>
        </div>
        <DeleteModal
            v-show="isDeleteModalVisible"
            @close="closeModal"
            @deleteForm="deleteForm(id)"
            :form="form"
            :isDeleting="this.isDeleting"
        />
        <CopyModal
            v-show="isCopyModalVisible"
            @close="closeModal"
            @duplicateForm="duplicateForm(id)"
            :form="form"
            :isCopying="this.isCopying"
        />
    </div>
</template>

<script>
// Alert Modal
import DeleteModal from "../utils/DeleteModal";
import CopyModal from "../utils/CopyModal";
// Icons
import EditIcon from "../utils/icons/EditIcon";
import DeleteIcon from "../utils/icons/DeleteIcon";
import CopyIcon from "../utils/icons/CopyIcon";

export default {
    components: {
        DeleteModal,
        CopyModal,
        EditIcon,
        CopyIcon,
        DeleteIcon
    },
    props: {
        form: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            isDeleteModalVisible: false,
            isDeleting: false,
            isCopyModalVisible: false,
            isCopying: false,
            id: this.form.id,
            name: this.form.name,
            created_at: this.form.created_at,
            showActions: false
        };
    },
    methods: {
        showDeleteModal() {
            this.isDeleteModalVisible = true;
        },
        deleteForm(id) {
            this.isDeleting = true;
            this.$store.dispatch("forms/deleteForm", id);
        },
        showCopyModal() {
            this.isCopyModalVisible = true;
        },
        duplicateForm(id) {
            this.isCopying = true;
            this.$store.dispatch("forms/duplicateForm", id).then(response => {
                if (response == "success") {
                    this.closeModal();
                }
            });
        },
        closeModal() {
            this.isDeleteModalVisible = false;
            this.isDeleting = false;
            this.isCopyModalVisible = false;
            this.isCopying = false;
        },
        setLocalStorage(tab) {
            localStorage.setItem("tab", tab);
        }
    }
};
</script>

<style scoped lang="scss">
@import "../../../../resources/sass/_variables.scss";
.form-item {
    margin: 1rem;
    border: 1px solid #cdcfd0;
    border-radius: 5px;
}
.inactive {
    width: 25px;
    margin-right: 25px;
    background-color: #cdcfd0;
}
.infoCard {
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.name-date {
    display: flex;
    width: 50%;
    align-items: center;
    justify-content: space-between;
}
.name {
    font-size: 1.3rem;
    font-weight: bolder;
    color: $darkBlue;
}
.date {
    font-size: 0.9rem;
    font-weight: 400;
    color: $greyHover;
}
.actions {
    width: 50%;
    display: flex;
    a {
        margin-right: 20px;
    }
    .action-icon {
        margin-right: 15px;
        svg {
            width: 30px;
            stroke: $grey;
        }
    }
    .action-icon:hover {
        svg {
            stroke: $greyHover;
        }
    }
}
.show-actions {
    justify-content: flex-end;
    width: 100%;
    display: flex;
}
</style>
