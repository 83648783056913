// initial state
const state = () => ({
    lenders: []
});

const getters = {};

const actions = {
    getLenders(context) {
        axios.defaults.headers.common["Authorization"] =
            "Bearer " + context.rootState.auth.token;
        return new Promise((resolve, reject) => {
            axios
                .get("/lenders")
                .then(response => {
                    context.commit("getLenders", response.data);
                })
                .catch(error => {
                    console.log(error);
                });
        });
    }
};

const mutations = {
    getLenders(state, lenders) {
        state.lenders = lenders;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
