<template>
    <div>
        <AlertSuccess v-if="this.$store.state.ui.successAlert" />
        <div class="edit-head" id="edit-nav">
            <div class="edit-menu">
                <div
                    class="menu-item"
                    @click="setActive('Form')"
                    :class="{ active: isActive('Form') }"
                >
                    Template Design
                </div>
                <div
                    class="menu-item"
                    @click="setActive('Button')"
                    :class="{ active: isActive('Button') }"
                >
                    Button Design
                </div>
                <div
                    class="menu-item"
                    @click="setActive('Options')"
                    :class="{ active: isActive('Options') }"
                >
                    Options
                </div>
            </div>

            <div class="form-name">
                <div style="display: flex; align-items: center;">
                    <div v-if="editFormName" class="name">
                        <input type="text" v-model="formData.name" />
                        <button
                            id="button-save"
                            class="save-name-button"
                            @click="updateFormName"
                        >
                            Save
                        </button>
                    </div>
                    <div
                        v-else
                        class="name"
                        @click="editName"
                        @mouseover="isHovering = true"
                        @mouseout="isHovering = false"
                    >
                        {{ formData.name }}
                    </div>
                    <div v-show="isHovering" class="edit-icon">
                        <EditIcon />
                    </div>
                </div>
                <div class="form-buttons">
                    <button class="reset-button" @click="resetStyle">
                        <ResetIcon />
                        Reset
                    </button>
                    <button
                        class="green-button"
                        @click="updateStyle"
                        id="save-edit"
                    >
                        Save
                    </button>

                    <button
                        class="green-button btn-get-code"
                        @click="showModal"
                    >
                        Get Code Snippet
                    </button>
                </div>
            </div>
        </div>
        <div class="main" v-if="styles.formId && templateData">
            <!-- EDIT SIDE -->
            <div class="edit-container">
                <div class="edit-components">
                    <FormDesign
                        @fileChanged="handleFileChanged"
                        :styles="styles"
                        v-show="activeItem == 'Form'"
                    />
                    <ButtonDesign
                        :styles="styles"
                        v-show="activeItem == 'Button'"
                    />
                    <Options
                        :styles="styles"
                        v-show="activeItem == 'Options'"
                    />
                    <p class="widget-note">
                        Note that this widget builder customizes design only. We
                        may update which fields appear in the form at some point
                        in the future.
                    </p>
                </div>
            </div>

            <!-- FORM SIDE -->
            <div class="form">
                <div id="device-container">
                    <div class="device-options-container">
                        <div class="device-icons">
                            <span
                                @click="selectDevice('mobile')"
                                :class="
                                    deviceSelected === 'mobile'
                                        ? 'device-active'
                                        : null
                                "
                            >
                                <MobileIcon />
                            </span>
                            <span
                                @click="selectDevice('tablet')"
                                :class="
                                    deviceSelected === 'tablet'
                                        ? 'device-active'
                                        : null
                                "
                            >
                                <TabletIcon />
                            </span>
                            <span
                                @click="selectDevice('desktop')"
                                :class="
                                    deviceSelected === 'desktop'
                                        ? 'device-active'
                                        : null
                                "
                            >
                                <DesktopIcon />
                            </span>
                        </div>
                    </div>
                    <div class="device-display-container">
                        <div
                            class="device-selected-container"
                            :class="'device-' + deviceSelected"
                        >
                            <div style="height: 100%">
                                <!-- form body -->
                                <div
                                    :style="styleObject"
                                    class="form-body"
                                    v-if="editSlide === 0"
                                >
                                    <iframe
                                        v-show="deviceSelected === 'desktop'"
                                        class="w-100 h-100"
                                        ref="inner"
                                        :src="`${bridgeUrl}`"
                                    >
                                    </iframe>
                                    <iframe
                                        v-show="deviceSelected === 'mobile' || deviceSelected === 'tablet'"
                                        class="w-100 h-100"
                                        ref="innerMobile"
                                        :src="`${bridgeUrl}&forceMobile=true`"
                                    >
                                    </iframe>
                                </div>
                                <!-- The Button -->
                                <div class="form-body" v-else>
                                    <div class="edit-button-container">
                                        <button
                                            :style="styleBtn"
                                            class="btn-embed"
                                            id="apply-button"
                                        >
                                            {{ this.styles.btnTxt }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Embed Modal  -->
            <GetCodeModal
                :formId="formId"
                :btnTxt="this.styles.btnTxt"
                :templateData="this.templateData"
                v-show="isGetCodeModalVisible"
                @close="closeModal"
            />

            <!-- end main -->
        </div>
        <div v-else><Loading /></div>
    </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import FormDesign from "./edit-components/Form";
import Options from "./edit-components/Options";
import ButtonDesign from "./edit-components/Button";
import GetCodeModal from "../utils/GetCodeModal";

// Templates
import Template1 from "../templates/template1";
// UI
import Loading from "../utils/Loading";
import AlertSuccess from "../utils/AlertSuccess";
// Icons
import ResetIcon from "../utils/icons/ResetIcon";
import EditIcon from "../utils/icons/EditIcon";
import MobileIcon from "../utils/icons/MobileIcon";
import TabletIcon from "../utils/icons/TabletIcon";
import DesktopIcon from "../utils/icons/DesktopIcon";

export default {
    name: "edit",
    components: {
        FormDesign,
        ButtonDesign,
        Options,
        Template1,
        Loading,
        AlertSuccess,
        EditIcon,
        GetCodeModal,
        ResetIcon,
        MobileIcon,
        TabletIcon,
        DesktopIcon
    },
    data() {
        return {
            templateData: null,
            activeItem: localStorage.getItem("tab"),
            is_FormActive: false,
            editFormName: false,
            formId: "",
            isHovering: false,
            isGetCodeModalVisible: false,
            editSlide: localStorage.getItem("tab") === "Button" ? 1 : 0,
            stylesFirstLoad: true,
            isResetStyle: false,
            deviceSelected: "mobile"
        };
    },
    created() {
        let id = this.$route.params.id;
        this.$store.dispatch("forms/getForm", id);
        this.insertGoogleApi();
    },
    mounted() {
        // Stick Edit Nav on top when scroll
        let navBar = document.getElementById("edit-nav");
        let sticky = navBar.offsetTop;
        window.document.onscroll = () => {
            let devices = document.getElementById("device-container");
            if (window.pageYOffset >= sticky) {
                navBar.classList.add("sticky");
                devices.classList.add("device-sticky");
            } else {
                navBar.classList.remove("sticky");
                devices.classList.remove("device-sticky");
            }
        };
    },
    computed: {
        ...mapState({
            formData: state => state.forms.form,
            styles: state => state.forms.styles,
            fonts: state => state.forms.fonts,
        }),
        styleObject: function() {
            return {
                "--bg-color": this.styles.bgColor,
                "--header-color": this.styles.headerColor,
                "--label-color": this.styles.labelColor,
                "--disclaimer-color": this.styles.disclaimerColor,
                "--primary-color": this.styles.primaryColor,
                "--secondary-color": this.styles.secondaryColor,
                "--border-size": this.styles.borderSize,
                "--border-radius": this.styles.borderRadius
            };
        },
        styleBtn: function() {
            return {
                "--btn-color": this.styles.btnColor,
                "--btn-border-color": this.styles.btnBorderColor,
                "--btn-txt-color": this.styles.btnTxtColor,
                "--btn-radius": this.styles.btnRadius,
                "--btn-size": this.styles.btnSize
            };
        },
        bridgeUrl() {
            if (!this.templateData) return '';

            const url = encodeURIComponent(this.templateData.url);
            const embedUrl = encodeURIComponent(this.templateData.url_lcembed);
            const templateType = this.formData.template_type;
            const formId = this.formId;

            return `${process.env.MIX_BRIDGE_URL}/${templateType}.html?formId=${formId}&url=${url}&embedUrl=${embedUrl}`;
        },
    },
    watch: {
        // watch changes on from
        // then load specific css
        formData(newValue, oldValue) {
            this.is_FormActive = newValue.is_active === 1 ? true : false;
            this.formId = newValue.form_id;
            this.loadStyle(newValue.form_id);

            var template_css = document.createElement("link");
            template_css.type = "text/css";
            template_css.rel = "stylesheet";
            template_css.href = `https://${process.env.MIX_DO_SPACES_BUCKET}.${process.env.MIX_DO_SPACES_URL}/assets/template${newValue.template_type}.css`;
            document.getElementsByTagName("head")[0].appendChild(template_css);

            // As we can't use variables for url on scss, this will append the css for specific form
            var partner_css = document.createElement("link");
            partner_css.type = "text/css";
            partner_css.rel = "stylesheet";
            partner_css.href = `https://${process.env.MIX_DO_SPACES_BUCKET}.${process.env.MIX_DO_SPACES_URL}/templates/${newValue.form_id}/${newValue.form_id}.css`;
            document.getElementsByTagName("head")[0].appendChild(partner_css);
            this.loadTemplateData();
        },
        // watch changes on styles to alert if not saved
        styles: {
            deep: true,
            handler(newValue, oldValue) {
                // check if the first load
                if (this.stylesFirstLoad) {
                    this.stylesFirstLoad = false;
                } else {
                    this.applyStyles(newValue);
                    this.checkForSaveAlert();
                }
            }
        }
    },
    methods: {
        selectDevice(device) {
            this.deviceSelected = device;
        },
        enableBrowserSaveAlert() {
            window.onbeforeunload = function() {
                return true;
            };
        },
        disableBrowserSaveAlert() {
            window.onbeforeunload = null;
        },
        checkForSaveAlert() {
            if (!this.isResetStyle) {
                this.enableBrowserSaveAlert();
            } else {
                this.isResetStyle = false;
                this.disableBrowserSaveAlert();
            }
        },
        disableSaveAlert() {
            this.disableBrowserSaveAlert();
        },
        // Dispatcha a call to get json setting from DO Spaces
        loadStyle(formId) {
            this.$store.dispatch("forms/getStyle", formId);
        },
        // Handle tabs
        isActive: function(menuItem) {
            return this.activeItem === menuItem;
        },
        // Handle tabs
        setActive: function(menuItem) {
            localStorage.setItem("tab", menuItem);
            this.activeItem = menuItem;
            if (menuItem == "Button") {
                this.editSlide = 1;
            } else {
                this.editSlide = 0;
            }
        },
        // activeForm() {
        //     this.formData.is_active = this.is_FormActive == true ? 1 : 0;
        //     this.$store.dispatch("forms/updateForm", this.formData);
        // },
        editName() {
            this.editFormName = true;
        },
        updateFormName() {
            this.editFormName = false;
            this.isHovering = false;
            this.$store.dispatch("forms/updateForm", this.formData);
        },
        //Butons actions
        updateStyle() {
            this.$store
                .dispatch("forms/updateStyle", this.styles)
                .then(response => {
                    if (response == "success") {
                        // Updated Alert
                        this.$store.dispatch("ui/updateAlert");
                        // Remove the "Need to save" alert
                        this.disableSaveAlert();
                    }
                });
        },
        resetStyle() {
            this.isResetStyle = true;
            this.$store.dispatch("forms/getStyle", this.styles.formId)
                .then(() => {
                    this.$root.$emit('resetFormImage');
                })
            //
        },
        insertGoogleApi() {
            // Insert Google Maps Api
            let google_api = document.createElement("script");
            google_api.type = "text/javascript";
            google_api.defer = true;
            google_api.src =
                "https://maps.googleapis.com/maps/api/js?libraries=places&v=weekly&key=AIzaSyBF1c7Ho1PwEsRzKGxy0WbnXgRbFf1s7U8";
            document.getElementsByTagName("head")[0].appendChild(google_api);
        },
        // Handle Modal
        showModal() {
            this.isGetCodeModalVisible = true;
        },
        closeModal() {
            this.isGetCodeModalVisible = false;
        },
        handleFileChanged(logo64Str) {
            this.applyStylesLogo(logo64Str);
        },
        applyStyles(styles) {
            const stylesStringify = JSON.stringify({styleData: styles});
            this.$refs.inner.contentWindow.postMessage(stylesStringify, '*');
            this.$refs.innerMobile.contentWindow.postMessage(stylesStringify, '*');
        },
        applyStylesLogo(logo64Str) {
            const logoDataStringify = JSON.stringify({imageData: logo64Str});
            this.$refs.inner.contentWindow.postMessage(logoDataStringify, '*');
            this.$refs.innerMobile.contentWindow.postMessage(logoDataStringify, '*');
        },
        loadTemplateData() {
            this.$store
                .dispatch('templates/getTemplateData', this.formData.template_type)
                .then((data) => {
                    this.templateData = data;
                })
        }
    },
};
</script>

<style scoped lang="scss">
@import "../../../../resources/sass/_variables.scss";
.form-body {
    height: 100%;
}
.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.sticky + .main {
    padding-top: 80px;
}
.device-sticky {
    position: fixed;
    top: 100px;
    left: 500px;
    right: 0;
}
.edit-head {
    background-color: #fff;
    border-bottom: 1px solid $inputBorder;
    display: flex;
    z-index: 9;
    .edit-menu {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        min-width: 500px;
        height: 100px;
        border-right: 1px solid $inputBorder;
        .menu-item {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 50%;
            cursor: pointer;
            font-size: 1.2rem;
            font-weight: 900;
            color: $darkBlue;
            border-bottom: 3px solid transparent;
            &:hover {
                color: $blue;
            }
        }
        .active {
            border-color: $blue;
        }
    }
    .form-name {
        height: 100px;
        width: 100%;
        min-width: 700px;
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .edit-icon {
            margin-left: 10px;
            margin-top: 5px;
            svg {
                width: 20px;
                stroke: $greyHover;
            }
        }
        .name {
            margin-left: 5px;
            min-width: 300px;
            font-size: 1.3rem;
            font-weight: 700;
            color: $darkBlue;
            cursor: pointer;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            input {
                height: 60px;
                border: 1px solid $inputBorder;
                padding: 10px;
                font-size: 20px;
                color: $darkBlue;
                border-radius: 5px;
            }
            .save-name-button {
                font-size: 1.2rem;
                border-radius: 5px;
                margin: 0 20px;
                height: 60px;
                width: 100px;
                color: #fff;
                background-color: $greenHover;
                border: 0;
            }
        }
        .form-buttons {
            display: flex;
            flex-direction: row;
        }
        .active {
            font-size: 1rem;
            font-weight: 500;
            color: $blue;
        }
        #save-edit {
            width: 200px;
            margin-right: 20px;
        }
    }
}
.main {
    display: flex;
    // justify-content: center;
    flex-direction: row;
}
button.btn-get-code {
    width: 200px;
    padding: 10px 20px;
}
.edit-container {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    max-width: 500px;
    min-width: 500px;
    border-right: 1px solid $inputBorder;

    .edit-components {
        padding: 20px 40px;
        font-size: 1.2rem;
        font-weight: 500;
        color: $darkBlue;
        min-height: 850px;
    }
    .select-element {
        padding: 0 20px;
        font-size: 0.9rem;
        color: $darkBlue;
        margin: 0px;
    }
}
#device-container {
    height: 100%;
    background-color: transparent;
    ::-webkit-scrollbar {
        width: 14px;
    }
    ::-webkit-scrollbar-thumb {
        border: 4px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        border-radius: 9999px;
        background-color: #ccc;
    }
}
.form {
    display: flex;
    flex-direction: column;
    flex-grow: 3;
    .lc-input-group {
        display: flex;
        flex-direction: column;
    }
    .device-options-container {
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        .device-icons {
            width: 160px;
            height: 60px;
            // border: 1px solid #ccc;
            border-radius: 5px;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            flex-direction: row;
            span {
                cursor: pointer;
                &:hover {
                    svg {
                        stroke: $darkBlue;
                    }
                }
            }
            .device-active {
                svg {
                    stroke: $darkBlue;
                }
            }
        }
    }
    .device-display-container {
        display: flex;
        justify-content: center;
        position: relative;
        width: 100%;
        height: 100%;
        .device-selected-container {
            overflow: hidden;
            transition: width 0.5s;
            .device-scroll {
                overflow: auto;
                height: 100%;
            }
        }
        .device-mobile {
            width: 35vh;
            height: 70vh;
            border: 10px solid $darkBlue;
            border-radius: 40px;
        }
        .device-tablet {
            width: 55vh;
            height: 70vh;
            border: 10px solid $darkBlue;
            border-radius: 40px;
        }
        .device-desktop {
            width: 100%;
            height: 70vh;
        }
    }
}
.widget-note {
    font-size: 0.7rem;
    color: $inputBorder;
    opacity: 0.8;
}
</style>
