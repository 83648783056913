<template>
    <div class="container-sm">
        <div class="row" style="justify-content: center">
            <div class="text-center mt-3 col-md-11 col-lg-8">
                <h1>Embed Guide</h1>
            </div>
            <div class="embed-options col-md-11 col-lg-8">
                <div
                    :class="active === 'html' ? 'active' : null"
                    @click="active = 'html'"
                >
                    <img :src="htmlLogo" alt="" />
                </div>
                <div
                    :class="active === 'wix' ? 'active' : null"
                    @click="active = 'wix'"
                >
                    <img :src="wixLogo" alt="" />
                </div>
                <div
                    :class="active === 'wordpress' ? 'active' : null"
                    @click="active = 'wordpress'"
                >
                    <img :src="wordpressLogo" alt="" />
                </div>
                <div
                    :class="active === 'godaddy' ? 'active' : null"
                    @click="active = 'godaddy'"
                >
                    <img :src="godaddyLogo" alt="" />
                </div>
            </div>
            <div class="col-md-11 col-lg-8" v-if="active === 'html'">
                <div class="step">
                    <p class="step-header">
                        Now that you have your new Form ready to go you’ll need
                        to paste the code snippet into the webpage you want the
                        Form to appear. Here’s how.
                    </p>
                    <h2>Step 1</h2>
                    <p>
                        Click on Copy this Code for either the Plain Form or for
                        the Button and Modal Form.
                    </p>
                    <img :src="step1" />
                </div>
                <div class="step">
                    <h2>Step 2</h2>
                    <p>
                        Now go to your webpage and paste the code snippet where
                        you would like it to be displayed inside the body of the
                        page. See an example below.
                    </p>
                    <img :src="step2" />
                </div>
                <div class="step">
                    <p>
                        After you insert the code snippet, save the file.
                        Refresh the webpage you added the code snippet to and
                        see the form or button embedded.
                        <br />
                        If you have any issues embedding the snippet please
                        contact us at
                        <a href="mailto:support@loanconnect.ca"
                            >support@loanconnect.ca</a
                        >
                    </p>
                </div>
            </div>
            <div class="col-md-11 col-lg-8" v-if="active === 'wix'">
                <div class="step">
                    <p class="step-header">
                        Now that you have your new Form ready to go you’ll need
                        to paste the code snippet into the webpage you want the
                        Form to appear. Here’s how.
                    </p>
                    <h2>Step 1</h2>
                    <p>
                        Click on Copy this Code for either the Plain Form or for
                        the Button and Modal Form.
                    </p>
                    <img :src="step1" />
                </div>
                <div class="step">
                    <h2>Step 2</h2>
                    <p>
                        Go to your wix page editor, select the page that you
                        want to embed, click on
                        <b>add -> Embed -> Custom Embeds -> Embed a Widget</b>
                    </p>
                    <img :src="wix_step2" />
                </div>
                <div class="step">
                    <h2>Step 3</h2>
                    <p>On the editor click on <b>Enter Code</b></p>
                    <img :src="wix_step3" />
                </div>
                <div class="step">
                    <h2>Step 4</h2>
                    <p>
                        On <b>HTML Settings</b>, select the <b>Code</b> option
                        and place your code snippet, than click <b>Update</b>
                    </p>
                    <img :src="wix_step4" />
                </div>
                <div class="step">
                    <h2>Step 5</h2>
                    <p>
                        After your form loads, adjust the height as you see fit
                        for the page
                    </p>
                    <img :src="wix_step5" />
                </div>
                <div class="step">
                    <p>
                        If you have any issues embedding the snippet please
                        contact us at
                        <a href="mailto:support@loanconnect.ca"
                            >support@loanconnect.ca</a
                        >
                    </p>
                </div>
            </div>
            <div class="col-md-11 col-lg-8" v-if="active === 'wordpress'">
                <div class="step">
                    <p class="step-header">
                        Now that you have your new Form ready to go you’ll need
                        to paste the code snippet into the webpage you want the
                        Form to appear. Here’s how.
                    </p>
                    <p>
                        *Make sure to use <b>WordPress 5.8</b> or a higher
                        version.
                    </p>
                </div>
                <div class="step">
                    <h2>Step 1</h2>
                    <p>
                        Click on Copy this Code for either the Plain Form or for
                        the Button and Modal Form.
                    </p>
                    <img :src="step1" />
                </div>
                <div class="step">
                    <h2>Step 2</h2>
                    <p>
                        Under your pages, either use the <b>Add New</b> button
                        to create a fresh page for your form, or <b>Edit</b> an
                        existing page to add the form code to
                    </p>
                    <img :src="wordpress_step1" />
                </div>
                <div class="step">
                    <h2>Step 3</h2>
                    <p>
                        Use the <b>+</b> in the header bar or within the page to
                        add a new content block
                    </p>
                    <img :src="wordpress_step2" />
                </div>
                <div class="step">
                    <h2>Step 4</h2>
                    <p>Seach and select<b> Custom HTML</b></p>
                    <img :src="wordpress_step3" />
                </div>
                <div class="step">
                    <h2>Step 5</h2>
                    <p>Paste the code snippet</p>
                    <img :src="wordpress_step4" />
                </div>
                <div class="step">
                    <h2>Step 6</h2>
                    <p>Click on <b>Preview</b> to load the form</p>
                    <img :src="wordpress_step5" />
                </div>
                <div class="step">
                    <h2>Step 7</h2>
                    <p>
                        Click on <b>Save draft</b> and <b>Publish</b> the page
                    </p>
                    <img :src="wordpress_step6" />
                </div>
                <div class="step">
                    <p>
                        If you have any issues embedding the snippet please
                        contact us at
                        <a href="mailto:support@loanconnect.ca"
                            >support@loanconnect.ca</a
                        >
                    </p>
                </div>
            </div>
            <div class="col-md-11 col-lg-8" v-if="active === 'godaddy'">
                <div class="step">
                    <p class="step-header">
                        Now that you have your new Form ready to go you’ll need
                        to paste the code snippet into the webpage you want the
                        Form to appear. Here’s how.
                    </p>
                </div>
                <div class="step">
                    <h2>Step 1</h2>
                    <p>
                        Click on Copy this Code for either the Plain Form or for
                        the Button and Modal Form.
                    </p>
                    <img :src="step1" />
                </div>
                <div class="step">
                    <h2>Step 2</h2>
                    <p>
                        On GoDaddy website builder, go to
                        <b>Site Navigation</b> and click on <b>+</b> to create a
                        <b>New Page</b>.
                    </p>
                    <img :src="godaddy_step1" />
                </div>
                <div class="step">
                    <h2>Step 3</h2>
                    <p>
                        Select <b>New Page</b> and give it a <b>Page Title</b>.
                        This will be the name of your page which will display on
                        the menu. Once you’ve set your title click
                        <b>Create Page</b>.
                    </p>
                    <img :src="godaddy_step2" />
                </div>
                <div class="step">
                    <h2>Step 4</h2>
                    <p>Click on <b> Add Section</b></p>
                    <img :src="godaddy_step3" />
                </div>
                <div class="step">
                    <h2>Step 5</h2>
                    <p>
                        Click on <b>Files & Web → HTML → Add</b> to add the area
                        where you’ll embed your snippet of code.
                    </p>
                    <img :src="godaddy_step4" />
                </div>
                <div class="step">
                    <h2>Step 6</h2>
                    <p>
                        Paste the snippet of code into the
                        <b>Custom Code</b> area, then click <b>Done</b>.
                    </p>
                    <img :src="godaddy_step5" />
                </div>
                <div class="step">
                    <h2>Step 7</h2>
                    <p>Click on <b>Publish</b></p>
                    <img :src="godaddy_step6" />
                </div>
                <div class="step">
                    <p>
                        If you have any issues embedding the snippet please
                        contact us at
                        <a href="mailto:support@loanconnect.ca"
                            >support@loanconnect.ca</a
                        >
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "guide",
    data() {
        return {
            active: "html",
            // Logos
            htmlLogo: `https://nyc3.digitaloceanspaces.com/${process.env.MIX_DO_SPACES_BUCKET}/resources/img/html-logo.png`,
            wixLogo: `https://nyc3.digitaloceanspaces.com/${process.env.MIX_DO_SPACES_BUCKET}/resources/img/wix-logo.png`,
            wordpressLogo: `https://nyc3.digitaloceanspaces.com/${process.env.MIX_DO_SPACES_BUCKET}/resources/img/wordpress-logo.png`,
            godaddyLogo: `https://nyc3.digitaloceanspaces.com/${process.env.MIX_DO_SPACES_BUCKET}/resources/img/godaddy-logo.png`,
            // Html
            step1: `https://nyc3.digitaloceanspaces.com/${process.env.MIX_DO_SPACES_BUCKET}/resources/img/guide_step_1.png`,
            step2: `https://nyc3.digitaloceanspaces.com/${process.env.MIX_DO_SPACES_BUCKET}/resources/img/guide_step_2.png`,
            // Wix
            wix_step2: `https://nyc3.digitaloceanspaces.com/${process.env.MIX_DO_SPACES_BUCKET}/resources/img/wix-embed-1.png`,
            wix_step3: `https://nyc3.digitaloceanspaces.com/${process.env.MIX_DO_SPACES_BUCKET}/resources/img/wix-embed-2.png`,
            wix_step4: `https://nyc3.digitaloceanspaces.com/${process.env.MIX_DO_SPACES_BUCKET}/resources/img/wix-embed-3.png`,
            wix_step5: `https://nyc3.digitaloceanspaces.com/${process.env.MIX_DO_SPACES_BUCKET}/resources/img/wix-embed-4.png`,
            // Wodpress
            wordpress_step1: `https://nyc3.digitaloceanspaces.com/${process.env.MIX_DO_SPACES_BUCKET}/resources/img/wordpress-embed-1.png`,
            wordpress_step2: `https://nyc3.digitaloceanspaces.com/${process.env.MIX_DO_SPACES_BUCKET}/resources/img/wordpress-embed-2.png`,
            wordpress_step3: `https://nyc3.digitaloceanspaces.com/${process.env.MIX_DO_SPACES_BUCKET}/resources/img/wordpress-embed-3.png`,
            wordpress_step4: `https://nyc3.digitaloceanspaces.com/${process.env.MIX_DO_SPACES_BUCKET}/resources/img/wordpress-embed-4.png`,
            wordpress_step5: `https://nyc3.digitaloceanspaces.com/${process.env.MIX_DO_SPACES_BUCKET}/resources/img/wordpress-embed-5.png`,
            wordpress_step6: `https://nyc3.digitaloceanspaces.com/${process.env.MIX_DO_SPACES_BUCKET}/resources/img/wordpress-embed-6.png`,
            // GoDaddy
            godaddy_step1: `https://nyc3.digitaloceanspaces.com/${process.env.MIX_DO_SPACES_BUCKET}/resources/img/godaddy-embed-1.png`,
            godaddy_step2: `https://nyc3.digitaloceanspaces.com/${process.env.MIX_DO_SPACES_BUCKET}/resources/img/godaddy-embed-2.png`,
            godaddy_step3: `https://nyc3.digitaloceanspaces.com/${process.env.MIX_DO_SPACES_BUCKET}/resources/img/godaddy-embed-3.png`,
            godaddy_step4: `https://nyc3.digitaloceanspaces.com/${process.env.MIX_DO_SPACES_BUCKET}/resources/img/godaddy-embed-4.png`,
            godaddy_step5: `https://nyc3.digitaloceanspaces.com/${process.env.MIX_DO_SPACES_BUCKET}/resources/img/godaddy-embed-5.png`,
            godaddy_step6: `https://nyc3.digitaloceanspaces.com/${process.env.MIX_DO_SPACES_BUCKET}/resources/img/godaddy-embed-6.png`
        };
    },
    created() {
        document.title = "LoanConnect - Install Guide";
    }
};
</script>

<style lang="scss">
.steps {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    .step-header {
        margin-bottom: 40px;
    }
}
.step {
    // width: 50%;
    margin-top: 40px;
    margin-bottom: 10px;
    img {
        width: 100%;
        border: 1px solid #356b8176;
        border-radius: 10px;
    }
    h2 {
        font-size: 1.5rem;
        font-weight: 900;
        color: #356b81;
    }
    p {
        color: #356b81;
        font-size: 1.2rem;
        font-weight: 400;
    }
}
.embed-options {
    overflow-y: auto;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    margin-top: 50px;
    div {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        height: 65px;
        img {
            max-height: 50px;
            max-width: 250px;
        }
    }
    .active {
        border-bottom: 1px solid #356b81;
    }
}
</style>
