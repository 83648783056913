var template = `
    <div class="lc-loan-option">
        <div class="lc-loan-head">
            <img :src="loan.logo_url"/>
            <button v-show="!isMobile()" class="lc-loan-btn"><a :href="loan.offer_destination" @click="nextSlide()" target="_blank">Select Offer</a></button>
        </div>
        <div class="lc-loan-info">
            <div class="lc-loan-detail">
                <h5>Loan Amount</h5>
                <h3>{{formatPrice(loan.offer)}}</h3>
            </div>
            <div class="lc-loan-detail">
                <h5>Monthly Payment*</h5>
                <h3>{{formatPrice(loan.montly_payment)}} /month</h3>
                <h5>for {{loan.term}} mo.</h5>
            </div>
            <div class="lc-loan-detail">
                <h5>Estimated APR*</h5>
                <h3>{{loan.apr}}%</h3>
            </div>
            <div class="lc-loan-detail">
                <h5>Interest + fees*</h5>
                <h3>{{formatPrice(loan.rates_fees)}}</h3>
            </div>
        </div>
        <button v-show="isMobile()" class="lc-loan-btn-mobile"><a :href="loan.offer_destination" @click="nextSlide()" target="_blank">Select Offer</a></button>
        <div class="lc-loan-offer-details">
            <div class="lc-loan-offer-details-head">
                <h4 @click="showDetails(loan.position)">Offer Details</h4>
            </div>
            <div v-show="activeDetail == loan.position" class="lc-loan-offer-details-body">
                {{loan.details}}
            </div>
        </div>

    </div>
`;

export default {
    template: template,
    props: {
        loan: {
            type: Object
        }
    },
    data() {
        return {
            activeDetail: 0
        };
    },
    methods: {
        showDetails(id) {
            if (this.activeDetail == id) {
                this.activeDetail = 0;
            } else {
                this.activeDetail = id;
            }
        },
        formatPrice(value) {
            var formatter = new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                minimumFractionDigits: 0
            });
            return formatter.format(value);
        },
        nextSlide() {
            console.log("Go to lender");
        },
        isMobile() {
            if (
                /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent
                )
            ) {
                return true;
            } else {
                return false;
            }
        }
    }
};
