<template>
    <transition name="modal-fade">
        <div class="modal-backdrop-component">
            <div class="delete-modal-component">
                <header>
                    <div class="close-icon" @click="handleUpdateColorModal">
                        <Close />
                    </div>
                </header>
                <section class="delete-modal-body">
                    <h3>
                        Are you sure you want to update the color scheme?
                    </h3>
                </section>
                <footer class="template-buttons">
                    <button
                        @click="handleUpdateColorModal"
                        class="cancel-button"
                    >
                        No, Don't Update
                    </button>
                    <button
                        @click="updateColorScheme()"
                        class="green-button mleft20"
                    >
                        Yes, Update
                    </button>
                </footer>
            </div>
        </div>
    </transition>
</template>

<script>
import Close from "./icons/CloseIcon";
import Spinner from "./Spinner";
export default {
    name: "modal",
    components: {
        Close,
        Spinner
    },
    props: {},
    data() {
        return {};
    },
    methods: {
        handleUpdateColorModal() {
            this.$emit("handleUpdateColorModal");
        },
        updateColorScheme() {
            this.$emit("updateColorScheme");
        }
    }
};
</script>

<style scoped lang="scss">
.delete-modal-component {
    background: #ffffff;
    box-shadow: 0 0.5rem 0.5rem rgb(0 0 0 / 20%);
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
    min-height: 30%;
    align-self: center;
    border-radius: 20px;
    padding: 20px;
}

.delete-modal-body {
    flex: 1 1 auto;
    padding: 0 20px;
}

.modal-fade-enter,
.modal-fade-leave-active {
    opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
    transition: opacity 0.2s ease-in-out;
}
@media only screen and (max-width: 720px) {
    .delete-modal-component {
        width: 90%;
        .delete-modal-body {
            h3 {
                font-size: 1.25rem;
            }
        }
    }
}
</style>
